import React, { Component } from 'react';
// import RUG, { DragArea, DropArea, Card, List } from 'react-upload-gallery';
import { ReactSortable } from "react-sortablejs";
// import 'react-dates/initialize';
// import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-upload-gallery/dist/style.css' // or scss

import IntroScreen from './IntroScreen';
import EndScreen from './EndScreen';
import StyleContainer from './StyleContainer';
import SelectQuestionType from './Question/SelectQuestionType';
import PreviewModal from './PreviewModal';
import image from '../../../../../assets/images';

import './index.scss';

const emptyQuestionFormArray = [
    {
        id: null, // Random ID of 8 digits,
        question: null, // Question
        questionDescription: null, // Question description / more info
        type: null, // Type of question
        values: [], // If array of values
        value: null, // If single string or int value

        allowSkipping: false, // False: Skip the question, or True: required
        enableComments: false, // Enable a textarea at the end
        chooseMultiple: false, // For choices (false is checkbox, true is radiobutton)
    }
];

class NewPoll extends Component {

    state = {
        focused: false,
        selectQuestionType: false,
        selectedQuestion: null,
        showIntroScreen: true,
        showEndScreen: false,
        viewport: null, // mobile, tablet, laptop
        form: {
            survey: {
                id: null, // If null is new, else 'edit'
                name: null,
            },
            style: {
                backgroundType: null, // null: none, 'color': color config, 'image': img url
                backgroundColor: '#fff',
                backgroundImage: null, // image.random.bg_buho2,
                fontFamily: null,
                fontColor: null,
                animation: 'open', // slider transition animation
                cssFile: null,
            },
            introData: {
                image: null,
                imageSize: 'cover',
                imageType: 'full-width',
                imagePosition: 'center center',
                imageRepeat: 'repeat',
                title: null,
                description: null,
                btnTitle: 'Start',
            },
            endData: {
                title: null,
                description: null,
                facebook: null,
                twiter: null,
                instagram: null,
                youtube: null,
                linkedin: null,
                twitch: null,
                tiktok: null,
                snpachat: null,
            },
            questionList: [
                {
                    id: 23040598, // Random ID of 8 digits,
                    question: 'How is the weather today?', // Question
                    questionDescription: null, // Question description / more info
                    type: 'multiple-choice', // Type of question
                    values: [
                        {
                            id: 71890336,
                            label: "Hot",
                        },
                        {
                            id: 90469389,
                            label: "Rainy",
                        },
                        {
                            id: 10469389,
                            label: "Cold",
                        },
                    ], // If array of values
                    value: null, // If single string value

                    allowSkipping: true, // False: Skip the question, or True: required
                    enableComments: false, // Enable a textarea at the end
                    chooseMultiple: false, // For choices (false is checkbox, true is radiobutton)
                },
                {
                    id: 15040190, // Random ID of 8 digits,
                    question: 'Select your favorite fruit:', // Question
                    questionDescription: null, // Question description / more info
                    type: 'multiple-choice', // Type of question
                    values: [
                        {
                            id: 21890336,
                            label: "Orange",
                        },
                        {
                            id: 30469389,
                            label: "Apple",
                        },
                        {
                            id: 50771307,
                            label: "Grapes",
                        },
                        {
                            id: 10771307,
                            label: "Bannana",
                        },
                    ], // If array of values
                    value: null, // If single string value

                    allowSkipping: true, // False: Skip the question, or True: required
                    enableComments: false, // Enable a textarea at the end
                    chooseMultiple: false, // For choices (false is checkbox, true is radiobutton)
                },
                {
                    id: 34050196, // Random ID of 8 digits,
                    question: 'Do you prefer Xbox or PS4?', // Question
                    questionDescription: null, // Question description / more info
                    type: 'multiple-choice', // Type of question
                    values: [
                        {
                            id: 71890336,
                            label: "Ps4",
                        },
                        {
                            id: 90469389,
                            label: "XBox",
                        },
                        {
                            id: 50771307,
                            label: "I don't know",
                        },
                    ], // If array of values
                    value: null, // If single string value

                    allowSkipping: true, // False: Skip the question, or True: required
                    enableComments: false, // Enable a textarea at the end
                    chooseMultiple: false, // For choices (false is checkbox, true is radiobutton)
                },
                
            ],
        },
    };

    componentDidMount = () => {
        // On Load Get Selected Survey Id and then clear it from Surveys page
        const { surveyId, clearSelectedSurveyId } = this.props;
        this.updateValue('survey', 'id', surveyId).then(() => clearSelectedSurveyId());
    }

    changeViewport = viewport => this.setState({ viewport });

    updateValue = (step, key, value) => {
        const newState = { ...this.state };
        newState.form[step][key] = value;
        console.log('newState...', newState);
        return new Promise(resolve => this.setState(newState, resolve));
    }

    showSelectQuestionType = id => {
        this.setState({
            showIntroScreen: false,
            showEndScreen: false,
            selectQuestionType: true,
            selectedQuestion: id,
        }, () => {
            console.log('SELECTED...', this.state.selectQuestionType, this.state.selectedQuestion);
        });
    }
    hideSelectQuestionType = () => this.setState({ selectQuestionType: false });

    showIntroScreen = () => {
        this.setState({
            showIntroScreen: true,
            showEndScreen: false,
            selectQuestionType: false,
            selectedQuestion: null,
        });
    }
    showEndScreen = () => {
        this.setState({
            showIntroScreen: false,
            showEndScreen: true,
            selectQuestionType: false,
            selectedQuestion: null,
        });
    }

    updateQuestionList = newList => {
        const newState = { ...this.state };
        newState.form['questionList'] = newList;
        this.setState(newState);
    }

    // Save new question and append to our questionList
    deleteQuestion = id => {
        const newFormState = { ...this.state.form };
         // Find wich element of array we're going to delete
        const objIndex = newFormState['questionList'].findIndex(x => x.id === id);
        newFormState['questionList'].splice(objIndex, 1); // Delete obj index

        // TODO: Save new State to Database...

        this.setState({ form: newFormState }, () => {
            this.hideSelectQuestionType(); // Close save question dialog when saving
            console.log('Question deleted...', newFormState);
        });
    }

    // Save new question and append to our questionList
    addNewQuestion = type => {
        const id = Math.floor(Math.random() * 99999999) + 10000000;
        const newState = { ...this.state };
        // Can’t I Use = to Copy an Array
        // const newQuestionList = emptyQuestionFormArray[0];

        const newQuestionList = {
            id: null, // Random ID of 8 digits,
            question: null, // Question
            questionDescription: null, // Question description / more info
            type: null, // Type of question
            values: [], // If array of values
            value: null, // If single string value
    
            allowSkipping: false, // False: Skip the question, or True: required
            enableComments: false, // Enable a textarea at the end
            chooseMultiple: false, // For choices (false is checkbox, true is radiobutton)
        };

        newQuestionList.id = id;
        newQuestionList.type = type;
        newQuestionList.question = '';
        newState.form['questionList'].push(newQuestionList);
        newState.selectedQuestion = id;

        // TODO: Save Question and fields to Database...

        this.setState(newState, () => {
            this.hideSelectQuestionType(); // Close save question dialog when saving
            console.log('New question added...', newState);
        });
    }

    // Save new question and append to our questionList
    saveQuestion = form => {
        const newState = { ...this.state };
        
         // Find wich element of array we're going to update
        const objIndex = newState.form['questionList'].findIndex(x => x.id === form.id);

        newState.form['questionList'][objIndex] = form; // Update obj

        // TODO: Save Question and fields to Database...

        this.setState(newState, () => {
            this.hideSelectQuestionType(); // Close save question dialog when saving
            console.log('Question saved...', newState);
        });
    }

    render() {
        const {
            form: {
                survey, questionList, style, introData, endData,
            },
            selectQuestionType,
            selectedQuestion,
            showIntroScreen,
            showEndScreen,
            viewport,
        } = this.state;

        const { closePane } = this.props;

        const backgroundImage = `url(${ style.backgroundImage })`;

        const backgroundPosition = style.backgroundPosition ? style.backgroundPosition : 'inherit';

        let hasBackgroundStyle = style.backgroundType === 'image' ? { backgroundImage, backgroundPosition } : { };
        hasBackgroundStyle = style.backgroundType === 'color' ? { backgroundColor: style.backgroundColor } : hasBackgroundStyle;

        return (
            <>
            { viewport &&
                <PreviewModal viewport={ viewport } changeViewport={ this.changeViewport } />
            }

            <div className={ `row new-survey ${ style.backgroundType === 'image' ? 'has-bg' : '' }` } style={ hasBackgroundStyle }>
                <div className="col-md-25 side-container">
                    <div className="side-content">

                        <div>
                            <div className="poll-section-title">Surv<span>Editor</span>
                                <div className="close" onClick={ () => closePane() }></div>
                            </div>

                            <div className="margin-b20">
                                {/* <Input title="Poll name..." value={ poll.name } onChange={ e => this.updateValue('poll', 'name', e.target.value) } /> */}
                                <input type="text" placeholder="Poll name..." value={ survey.name } onChange={ e => this.updateValue('survey', 'name', e.target.value) } />
                            </div>
                        </div>

                        {/* <div className={ `block text-center margin-t30 ${ !poll.name ? 'hidden' : '' }` }>
                            <button type="button" className="btn-md btn-primary" onClick={ () => console.log('save') }>Save poll name</button>
                        </div> */}

                        <hr />

                        <ul className="fields-list">
                            <li className="no-burger">
                                <b onClick={ () => this.showIntroScreen() }>
                                    Intro Screen
                                </b>
                            </li>
                            <ReactSortable
                                list={ questionList }
                                setList={ newState => this.updateQuestionList(newState) }
                                animation={ 300 }
                            >
                                { (questionList || []).map(item => (
                                    <li key={ item.id }>
                                        <b
                                            onClick={ () => this.showSelectQuestionType(item.id) }
                                        >{ item.question ? item.question.replace(/(.{26})..+/, "$1…") : <i>Untitled...</i> }</b>
                                        <div className="bu"><span></span><span></span><span></span><span></span></div>
                                        {/* <div className="icon edit" title="edit"></div> */}
                                        {/* <div
                                            className="icon delete"
                                            title="delete"
                                            onClick={ () => this.deleteQuestion(item.id) }
                                        ></div> */}
                                    </li>
                                )) }
                            </ReactSortable>
                            <li className="no-burger">
                                <b onClick={ () => this.showEndScreen() }>
                                    End Screen
                                </b>
                            </li>
                        </ul>
                        
                        <div className="block text-center margin-t30">
                            <div className="btn-md btn-primary" onClick={ () => this.showSelectQuestionType(null) }>+ Add new question</div>
                        </div>

                    </div>
                </div>

                <div className="col-md-75 col-md-offset-25 main-container">
                    { !showIntroScreen && !showEndScreen &&
                        <SelectQuestionType
                            // key={ Math.floor(Math.random() * 999) + 100 } // This will allow reseting the component when closed
                            visible={ selectQuestionType }
                            hideSelectQuestionType={ this.hideSelectQuestionType }
                            addNewQuestion={ this.addNewQuestion }
                            saveQuestion={ this.saveQuestion }
                            // Enter to edit mode if not null
                            selectedQuestion={ selectedQuestion }
                            questionData={ selectedQuestion ? questionList.filter(e => e.id === selectedQuestion) : emptyQuestionFormArray }
                            emptyQuestionFormArray={ emptyQuestionFormArray }
                        />
                    }
                    { showIntroScreen && !showEndScreen &&
                        <IntroScreen
                            updateValue={ this.updateValue }
                            introData={ introData }
                        />
                    }
                    { !showIntroScreen && showEndScreen &&
                        <EndScreen
                            updateValue={ this.updateValue }
                            endData={ endData }
                        />
                    }

                </div>

                <div className="col-md-2 style-container">
                    <StyleContainer
                        updateValue={ this.updateValue }
                        style={ style }
                        changeViewport={ this.changeViewport }
                    />
                </div>
            </div>
            </>
        );
    }
}

export default NewPoll;
