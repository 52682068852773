import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import About from '../../components/About';
import Password from '../../components/Password';
import 'react-sliding-pane/dist/react-sliding-pane.css';
// import assets from '../../../../assets/images';
import '../../../css/index.scss';


class AccountLayout extends Component {

    state = {
        isAboutPaneOpen: false,
        title: '',
        defaultComponent: undefined,
    }

    setComponent = (defaultComponent, title) => {
        this.setState({ defaultComponent, title, isAboutPaneOpen: true });
    }

    render() {
        const {
            isAboutPaneOpen,
            defaultComponent,
            title,
        } = this.state;

        return (
            <div className="description wrapper">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <h1>Account</h1>

                        <ul className="property-list">
                            <li onClick={ () => this.setComponent(<About />, 'Account & Profile') } role="button" tabIndex="">
                                <div className="info">
                                    <div className="name">Account &amp; Profile</div>
                                    <div className="type">Name, email, language, business</div>
                                </div>
                            </li>
                            <li onClick={ () => this.setComponent(<About />, 'Billing') } role="button" tabIndex="">
                                <div className="info">
                                    <div className="name">Billing</div>
                                </div>
                            </li>
                            <li onClick={ () => this.setComponent(<Password />, 'Change Password') } role="button" tabIndex="">
                                <div className="info">
                                    <div className="name">Change Password</div>
                                </div>
                            </li>
                            <li onClick={ () => this.setComponent(<About />, 'Delete Account') } role="button" tabIndex="">
                                <div className="info">
                                    <div className="name">Delete Account</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <SlidingPane
                    className={ 'slide-pane' }
                    overlayClassName={ 'some-custom-overlay-class' }
                    isOpen={ isAboutPaneOpen }
                    from={ 'right' }
                    width={ '60%' }
                    title={ title }
                    onRequestClose={ () => {
                        this.setState({ isAboutPaneOpen: false });
                    } }>
                    { defaultComponent }
                </SlidingPane>
            </div>
        );
    }
}

export default withRouter(AccountLayout);
