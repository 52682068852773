import React from 'react';
import { Provider } from 'react-redux';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './modules/Auth/actions/user-action';
import store from './Redux/store';
import { Routes } from './routes';
// import { getCart } from './modules/Cart/actions/cart-action';

// If token stored we add it to all requests headers
if (localStorage.token) {
    setAuthToken(localStorage.token);
}

class App extends React.Component {

    // Load user info if token already exists
    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <Provider store={ store }>
                <Routes/>
            </Provider>
        );
    }

}

export default App;
