import React, { Component } from 'react';
import { ReactSortable } from "react-sortablejs";

class MultipleChoice extends Component {

    // Add new option text input to the field list
    appendNewChoice = () => {
        const { updateValue, values } = this.props;
        const newChoiceList = values;

        // Append new row to existing values list
        newChoiceList.push({
            id: Math.floor(Math.random() * 99999999) + 10000000, // Random ID of 8 digits
            label: null,
        });

        updateValue('values', newChoiceList); // Set new values array to old array
    }

    // Update label to current array index that is being updated
    saveChoiceValue = (id, value) => {
        const { updateValue, values } = this.props;
        const newValues = values;
        const objIndex = newValues.findIndex(x => x.id === id); // Find wich element of array is

        newValues[objIndex].label = value; // Set value to label
        updateValue('values', newValues); // Update array of values
    }

    render() {
        const { updateValue, values } = this.props;

        return (
            <>
                <ul className="fields-list">
                    <ReactSortable
                        list={ values }
                        setList={ newState => updateValue('values', newState) }
                        animation={ 300 }
                    >
                        { (values || []).map(item => (
                            <li key={ item.id }>
                                <input
                                    type="text"
                                    className="options-input"
                                    value={ item.label }
                                    onChange={ e => this.saveChoiceValue(item.id, e.target.value) }
                                />
                                <div className="bu"><span></span><span></span><span></span><span></span></div>
                            </li>
                        )) }
                    </ReactSortable>
                </ul>
                <div className="block text-center margin-t10">
                    <div className="btn-sm block btn-gray" onClick={ () => this.appendNewChoice() }>+ Add new choice</div>
                </div>
            </>
        );
    }
}

export default MultipleChoice;
