import React from 'react';
import RegisterLayout from '../../layouts/Register';
import { connect } from 'react-redux';
import { externalLogin } from '../../actions/user-action';
import * as queryString from 'query-string';

class Register extends React.Component {
    render() {
        const parse = queryString.parse(this.props.location.search);
        const { externalLogin } = this.props;
        const pathQuery = parse.form;

        return (
            <RegisterLayout pathQuery={ pathQuery } externalLogin={ externalLogin }/>
        );
    }
}

export default connect(null, { externalLogin })(Register);
