import React, { Component } from 'react';
// import './index.scss';

class OpenText extends Component {

    render() {
        const { updateValue, value } = this.props;

        return (
            <div className="open-text">
                <div className="row">
                    <div className="col-md-1"></div>
                    
                </div>
            </div>
        );
    }
}

export default OpenText;
