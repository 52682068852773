import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import moment from 'moment';
import NewSurvey from '../../components/NewSurvey';
import NewSurveyModal from '../../components/NewSurveyModal';
import 'react-sliding-pane/dist/react-sliding-pane.css';
// import assets from '../../../../assets/images';
import '../../../css/index.scss';
import './index.scss';

const polls = {
    results:[
        {
            id: 911460281,
            name: 'BMW series 1 campaign',
            createdAt: new Date('2020-05-10'),
            answerCount: 1200,
        },
        {
            id: 131460585,
            name: 'BMW X6 M campaign',
            createdAt: new Date('2020-02-28'),
            answerCount: 893,
        },
    ],
};

class SurveysLayout extends Component {

    state = {
        isPaneOpen: false,
        showNewSurveyModal: false,
        selectedSurveyId: null,
    }

    showPane = surveyId => this.setState({ isPaneOpen: true });
    closePane = () => this.setState({ isPaneOpen: false });

    showNewSurveyModal = () => this.setState({ showNewSurveyModal: true });
    hideNewSurveyModal = () => this.setState({ showNewSurveyModal: false });

    render() {
        const {
            isPaneOpen, showNewSurveyModal, selectedSurveyId,
        } = this.state;

        return (
            <div className="description wrapper">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <h1>Surveys
                            <button
                                className="btn-md btn-primary"
                                onClick={ () => this.showNewSurveyModal() }
                            >Create a survey</button>
                        </h1>

                        <input type="text" className="filter" placeholder="Filter results..." />

                        <ul className="property-list">
                            { polls.results.map((poll, i) =>
                                <li className="row" key={ i }>
                                    <div className="col-md-9">
                                        <div className="info">
                                            <div className="name">{ poll.name }</div>
                                            <div className="type">Created at { moment(poll.createdAt).format('MMMM D YYYY, h:mm a') }</div>
                                        </div>
                                    </div>
                                </li>
                            ) }
                        </ul>
                    </div>
                </div>

                <SlidingPane
                    className={ 'slide-pane survey-pane' }
                    // overlayClassName={ '.slide-pane-overlay' }
                    isOpen={ isPaneOpen }
                    // isOpen={ true }
                    from={ 'right' }
                    width={ '100%' }
                    title={ 'Create new poll' }
                    onRequestClose={ () => {
                        this.setState({ isPaneOpen: false });
                    } }>
                    <NewSurvey
                        surveyId={ selectedSurveyId }
                        clearSelectedSurveyId={ () => this.setState({ selectedSurveyId: null }) }
                        closePane={ this.closePane }
                    />
                </SlidingPane>

                { showNewSurveyModal &&
                    <NewSurveyModal
                        showPane={ this.showPane }
                        hideModal={ this.hideNewSurveyModal }
                    />
                }

            </div>
        );
    }
}

export default withRouter(SurveysLayout);
