import React, { Component } from 'react';
import Input from '../../../../../../Core/components/Input';

class FontContainer extends Component {

    render() {
        const { updateValue, style } = this.props;

        return (
            <>
                <Input
                    type="select"
                    value={ style.transition }
                    onChange={ e => updateValue('style', 'transition', e.target.value) }
                    options={ [
                        {
                            value: 'open-sans',
                            text: 'Open Sans',
                        },
                        {
                            value: 'roboto',
                            text: 'Roboto',
                        },
                    ]}
                />
            </>
        );
    }
}

export default FontContainer;
