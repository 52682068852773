import React, { Component } from 'react';
import VerifyAuthLayout from '../../layouts/VerifyAuth';
import * as queryString from 'query-string';
import { connect } from 'react-redux';
import { externalLogin } from '../../actions/user-action';

class VerifyAuth extends Component {
    render() {
        const parse = queryString.parse(this.props.location.search);
        const { externalLogin } = this.props;
        const pathQuery = parse.form;

        return (
            <div>
                <VerifyAuthLayout pathQuery={ pathQuery } externalLogin={ externalLogin }/>
            </div>
        );
    }
}

export default connect(null, { externalLogin })(VerifyAuth);
