import React, { Component } from 'react';
import './index.scss';

class PreviewModal extends Component {
    render() {
        const { viewport, changeViewport } = this.props;
        return (
            <div className="preview-modal">
                <div className="head">
                    <div className="mobile" onClick={ () => changeViewport('mobile') }></div>
                    <div className="tablet" onClick={ () => changeViewport('tablet') }></div>
                    <div className="laptop" onClick={ () => changeViewport('laptop') }></div>
                    <div
                        className="close"
                        onClick={ () => changeViewport(null) }
                    ></div>
                </div>
                <div className={ `preview-iframe-container prev-${ viewport }` }>
                    <iframe title="preview" src="http://localhost:3000" frameBorder="0"></iframe>
                </div>
            </div>
        );
    }
}

export default PreviewModal;
