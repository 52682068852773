import React, { Component } from 'react';
import Input from '../../../../Core/components/Input';
import './index.scss';

class Password extends Component {

    state = {
        form: {
            oldPassword: null,
            newPassword: null,
            repeatNewPassword: null,
        },
    };

    updateValue = (key, value) => {
        const newState = { ...this.state };
        newState.form[key] = value;
        this.setState(newState, () => {
            console.log('newState...', newState);
        });
    };

    render() {
        const {
            form: {
                name, email, mobile, phone,
            },
        } = this.state;

        return (
            <div className="row password">
                <div className="col-md-8 col-md-offset-2">

                    <div className="section-title">Change Password</div>

                    <div className="margin-b20">
                        <Input type="password" title="Current password" value={ name } onChange={ e => this.updateValue('name', e.target.value) } />
                    </div>

                    <hr />
                    <div className="margin-b20">
                        <Input type="password" title="New password" value={ email } onChange={ e => this.updateValue('email', e.target.value) } />
                    </div>
                    <div className="margin-b20">
                        <Input type="password" title="Repeat new password" value={ mobile } onChange={ e => this.updateValue('mobile', e.target.value) } />
                    </div>

                    <div className={ `block text-center margin-t30` }>
                        <button type="button" className="btn-big btn-primary" onClick={ () => this.nextStep() }>Save changes</button>
                    </div>

                </div>
            </div>
        );
    }
}

export default Password;
