import React, { Component } from 'react';
import ImageUploader from "react-images-upload";
import Input from '../../../../../../Core/components/Input';
import BackgroundPosition from './BackgroundPosition';

class Background extends Component {

    state = {
        pictures: [],
        pictureLocalURL: null,
    }

    onDrop = (pictureFiles, pictureDataURLs) => {
        const { updateValue } = this.props;

        // Reset pictures arr first (only 1 img is allowed)
        this.setState({ pictures: [] });
        ///

        console.log('pictureFiles', pictureFiles);
        console.log('pictureDataURLs', pictureDataURLs);

        this.setState({
            // pictures: this.state.pictures.concat(pictureFiles),
            pictures: pictureFiles,
            pictureLocalURL: pictureDataURLs[pictureDataURLs.length - 1],
        }, () => console.log(this.state.pictures));

        updateValue('style', 'backgroundImage', pictureDataURLs[pictureDataURLs.length - 1]);
    }

    render() {
        const { updateValue, style } = this.props;
        const { pictures, pictureLocalURL } = this.state;

        const backgroundImage = pictureLocalURL ? `url(${ pictureLocalURL })` : null;

        const colorList = [
            '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC',
            '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#111111',
        ];

        return (
            <>
                <Input
                    type="select"
                    value={ style.backgroundType }
                    onChange={ e => updateValue('style', 'backgroundType', e.target.value) }
                    options={ [
                        {
                            value: null,
                            text: 'No background',
                        },
                        {
                            value: 'color',
                            text: 'Solid color / gradient',
                        },
                        {
                            value: 'image',
                            text: 'Background image',
                        },
                    ]}
                />

                { style.backgroundType === 'color' &&
                    <div className="test">
                        <ul className="color-picker">
                            { colorList.map((e, i) =>
                                <li
                                    key={ i }
                                    style={ { background: `${ e }` } }
                                    onClick={ () => updateValue('style', 'backgroundColor', e) }
                                ></li>
                            ) }
                        </ul>
                        <div className="input-has-pre">
                            <span>#</span>
                            <input
                                type="text"
                                className="border-bottom-only"
                                value={ style.backgroundColor.replace('#','').toUpperCase() }
                                maxLength={ 6 }
                                onChange={ e => updateValue('style', 'backgroundColor', `#${ e.target.value === '' ? 'FFF' : e.target.value.replace(/[^a-fA-F0-9]/gi,'').toUpperCase() }`) }
                            />
                        </div>
                    </div>
                }

                { style.backgroundType === 'image' &&
                    <>
                        { pictureLocalURL &&
                            <div className="image-preview" style={ { backgroundImage } }></div>
                        }
                        <ImageUploader
                            withIcon={ true }
                            buttonText="Choose image"
                            onChange={ this.onDrop }
                            imgExtension={ [".jpg", ".png"] }
                            maxFileSize={ 5242880 }
                        />
                        <BackgroundPosition
                            updateValue={ updateValue }
                            style={ style }
                        />
                    </>
                }
            </>
        );
    }
}

export default Background;
