import React from 'react';
import './index.scss';


class Input extends React.Component {

    saveCheckgroup = val => {
        const { updateValue, questionId, navigateSlider } = this.props;
        updateValue(questionId, 'value', val).then(() => {
            navigateSlider('next');
        });
    }

    render() {
        const { title, onChange, options, value, style, type } = this.props;

        let theInput;

        switch (type) {
            case 'select':
                return (
                    <div className="app-input" style={ style }>
                        <select onChange={ onChange }>{ options.map((e, i) => <option key={ i } value={ e.value }>{ e.text }</option>) }</select>
                    </div>
                );

            case 'textarea':
                theInput = <textarea onChange={ onChange } required>{ value }</textarea>;
                break;

            case 'checkbox':
                return (
                    <div className={ `check ${ value ? 'checked' : '' }` } onClick={ () => onChange(!value) } style={ style }>
                        <div></div>
                        <span>{ title }</span>
                    </div>
                );

            case 'checkgroup':
                return (
                    <div className="checkgroup">
                        { options.map((e, i) => (
                            <div className={ `check-opt ${ e.key === value ? 'checked' : '' }` } onClick={ () => this.saveCheckgroup(e.key) } key={ i }>
                                { e.value }
                            </div>
                        )) }
                    </div>
                );

            case 'label':
                return <label>{ title }</label>;

            case 'password':
                theInput = <input type="password" value={ value } onChange={ onChange } required />;
                break;

            default:
                theInput = <input type="text" value={ value } onChange={ onChange } required />;
        }

        return (
            <div className="app-input" style={ style }>
                { theInput }
                <span className="bar"></span>
                <label>{ title }</label>
            </div>
        );
    }
}

export default Input;
