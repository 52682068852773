import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { login, logout, register } from "../../../../actions/user-actions";
import { connect } from 'react-redux';
import Login from '../Login';
import Register from '../Register';
import './index.scss';

class Header extends Component {

    state = {
        showLogin: false,
        showRegister: false,
    }

    closeAuth = () => this.setState({ showLogin: false, showRegister: false });
    showLogin = () => this.setState({ showLogin: true, showRegister: false });
    showRegister = () => this.setState({ showRegister: true, showLogin: false });

    render() {
        // const { isAuthenticated, lang, setLanguage, langText } = this.props;
        const { showLogin, showRegister } = this.state;
        const { history, login, register, logout } = this.props;

        const isBasePathActive = this.props.location.pathname === '/';

        return (
            <div className={ `header-rg ${ isBasePathActive ? 'fixed scroll no-shadow' : '' }` }>
                <div className="container">
                    <div className="header-parent clearfix">
                        <div className="menu-mask"></div>
                        <a id="menuLink" className="menu-link"><span></span></a>
                        <Link className={ `logo  ${ isBasePathActive ? 'whitee' : '' }` } to={ '/' }>Rentolugar</Link>

                        <ul className="main-nav nav-sm">
                            <li><Link to="/examples">Examples</Link></li>
                            <li><Link to="/pricing">Guides</Link></li>
                            <li><Link onClick={ () => this.showLogin() }>Login</Link></li>
                            <li className="register-cta"><Link onClick={ () => this.showRegister() }>Sign up
                                free &#x2192;</Link></li>
                        </ul>
                    </div>
                </div>

                { showLogin === true || showRegister === true
                    ? <div className="auth">
                        <div className="modal">
                            <div className="close" onClick={ () => this.closeAuth() } role="button"
                                 tabIndex={ 0 }></div>
                            { showLogin &&
                            <Login showRegister={ this.showRegister } login={ login } history={ history }/> }
                            { showRegister &&
                            <Register showLogin={ this.showLogin } register={ register } history={ history }/> }
                        </div>
                    </div>
                    : <></>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.User.isAuthenticated,
});

export default connect(mapStateToProps, { login, logout, register })(withRouter(Header));