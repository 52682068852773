import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {logout} from "../../../../../actions/user-actions";
import { connect } from 'react-redux';
import Pricing from '../../../../Core/components/Pricing';
import './index.scss';

class PanelSidebar extends Component {

    state = {
        showPricingModal: false,
    }

    logoutUser = () => {
        const { logout } = this.props;
        logout();
    }

    hideModal = () => this.setState({ showPricingModal: false });

    render() {
        const { pathname } = this.props.location;
        const { showPricingModal } = this.state;

        return (
            <>
                <div className="panel-menu">
                    <div className="logo white"></div>
                    <ul>
                        <li className={ pathname.includes('dashboard') ? 'selected' : '' }>
                            <Link to="/panel/dashboard">Dashboard</Link>
                        </li>
                        <li className={ pathname.includes('surveys') ? 'selected' : '' }>
                            <Link to="/panel/surveys">Surveys</Link>
                        </li>
                    </ul>
                    <hr />
                    <ul>
                        <li
                            className={ pathname.includes('upgrade') ? 'selected' : '' }
                            onClick={ () => this.setState({ showPricingModal: true }) }
                        >
                            <Link className="yellow">Upgrade now <span className="icon lock yellow"></span></Link>
                        </li>
                        <li className={ pathname.includes('account') ? 'selected' : '' }>
                            <Link to="/panel/account">Account</Link>
                        </li>
                        <li><Link to="/" onClick={ this.logoutUser }>Log out</Link></li>
                    </ul>
                </div>

                { showPricingModal &&
                    <div className="full-modal">
                        <div className="full-modal-mask">
                            <div className="full-modal-container">
                                <Pricing hideModal={ this.hideModal } />
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }

}

export default connect(null, { logout })(withRouter(PanelSidebar));
