import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import './index.scss';

class DateTime extends Component {

    componentDidMount() {
        const { updateValue } = this.props;
        updateValue('values', {
            date: new Date(),
            hh: null,
            mm: null,
            md: null, // meridiem (am, pm)
        });
    }

    handleChange = (key, val) => {
        const { updateValue, values } = this.props;
        const newObj = { ...values };
        newObj[key] = val;
        updateValue('values', newObj);
    }
    
    render() {
        const { values } = this.props;

        return (
            <div className="row date-time margin-t30">
                <div className="col-md-6">
                    <DatePicker
                        onChange={ e => this.handleChange('date', e) }
                        value={ values.date }
                    />
                </div>
                <div className="col-md-2">
                    <div className="padding-r10">
                        <input
                            placeholder="hh"
                            value={ values.hh }
                            maxLength={ 2 }
                            onChange={ e => this.handleChange('hh', e.target.value) }
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="padding-r10">
                        <input
                            placeholder="mm"
                            value={ values.mm }
                            maxLength={ 2 }
                            onChange={ e => this.handleChange('mm', e.target.value) }
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <select
                        value={ values.md }
                        onChange={ e => this.handleChange('md', e.target.value) }
                    >
                        <option value='am'>am</option>
                        <option value='pm'>pm</option>
                    </select>
                </div>
            </div>
        );
    }
}

export default DateTime;
