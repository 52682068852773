import React, { Component } from 'react';
import './index.scss';

class Dashboard extends Component {
    render() {
        return (
            <div className="dashboard">
                <div className="row">
                    <div className="col-md-3">
                        <div className="info-box">
                            <div className="result">325</div>
                            <div className="title">Responses</div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info-box">
                            <div className="result">5</div>
                            <div className="title">Open surveys</div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info-box">
                            <div className="result">94%</div>
                            <div className="title">Avg. completition rate</div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info-box">
                            <div className="result">00h:01m:24s</div>
                            <div className="title">Avg. completition time</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;
