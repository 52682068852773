import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import './index.scss';

class ImageChoice extends Component {

    state = {
        files: []
    }

    onDrop = acceptedFile => {
        // console.log('acceptedFile', acceptedFile);
        // const { files } = this.state;
        const { values, updateValue } = this.props;
        const newArr = [ ...values ];
        acceptedFile.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            pouTitle: null
        }));
        newArr.push( ...acceptedFile );
        // this.setState({ files: newArr });
        updateValue('values', newArr);
    }

    updateTitle = (arrPos, value) => {
        const { values, updateValue } = this.props;
        values[arrPos].pouTitle = value;
        updateValue('values', values);
    }

    render() {
        const { values } = this.props;
        // const { files } = this.state;

        return (
            <div className="row image-choice">

                <div className="col-md-4">
                    <div className="item">
                        <Dropzone
                            onDrop={ this.onDrop }
                            multiple={ true }
                            accept={ 'image/jpeg, image/jpg, image/png' }
                        >
                            { ({ getRootProps, getInputProps }) => (
                                <div { ...getRootProps({className: 'dropzone'}) }>
                                    <input { ...getInputProps() } />
                                    <p>Drop your images here, or click to select your images...</p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </div>

                { values.map((e, i) =>
                    <div className="col-md-4" key={ i }>
                        <div className="item">
                            <div className="image bg-contain" style={ { backgroundImage: `url(${ e.preview })` } }></div>
                            <div className="text-input">
                                <input type="text"
                                    value={ e.pouTitle }
                                    onChange={ e => this.updateTitle(i, e.target.value) }
                                />
                            </div>
                        </div>
                    </div>
                ) }

            </div>
        );
    }
}

export default ImageChoice;
