import {
    CREATE_SURVEY,
    UPDATE_SURVEY,
    GET_SURVEYS,
    DELETE_SURVEY,
    USER_LOGOUT,
    SURVEY_LOADING,
    SURVEY_STOP_LOADING
} from '../constants/action-types';

const initialState = {
    surveys: [],
    survey: null,
    loading: true,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_SURVEY:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_SURVEY:
            return {
                ...state,
                loading: false,
            };
        case GET_SURVEYS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_SURVEY:
            return {
                ...state,
                loading: false,
            };
        case SURVEY_LOADING:
            return {
                ...state,
                loading: true,
            };
        case SURVEY_STOP_LOADING:
            return {
                ...state,
                loading: false,
            };
        case USER_LOGOUT:
            return {
                ...state,
                surveys: [],
                survey: null,
                loading: true,
            };
        default:
            return state;
    }
};
