import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import '../../../css/index.scss';
import './index.scss';
import Input from '../../../../Core/components/Input';

class SurveysLayout extends Component {

    state = {
        isPaneOpen: false,
        showNewSurveyModal: true,
        surveyName: null,
        surveyTarget: null,
    }

    createSurvey = () => {
        const { showPane, hideModal } = this.props;

        // TODO: Create survey record in DB and save survey name
        showPane(Math.floor(Math.random() * 99999999) + 10000000);

        // After saving, close modal
        hideModal();
    }


    render() {
        const { surveyName, surveyTarget } = this.state;

        return (
            <div className="new-survey-modal">
                <div className="full-modal-mask">
                    <div className="full-modal-container">
                        <div className="row">
                            <div className="col-md-7">
                                <Input
                                    title="Survey name"
                                    value={ surveyName }
                                    onChange={ e => this.setState({ surveyName: e.target.value }) }
                                />
                            </div>
                            <div className="col-md-5">
                                <Input
                                    type="select"
                                    title="Target audience"
                                    value={ surveyTarget }
                                    onChange={ e => this.setState({ surveyTarget: e.target.value }) }
                                    options={
                                        [
                                            {
                                                value: null,
                                                text: 'Select audience...'
                                            },
                                            {
                                                value: 'general',
                                                text: 'General Public'
                                            },
                                            {
                                                value: 'automotive',
                                                text: 'Automotive'
                                            },
                                            {
                                                value: 'finance',
                                                text: 'Finance'
                                            },
                                            {
                                                value: 'it',
                                                text: 'IT'
                                            },
                                        ]
                                    }
                                />
                            </div>
                        </div>

                        <button
                            type="button"
                            className="btn-lg btn-primary"
                            onClick={ () => this.createSurvey() }
                        >Create survey</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SurveysLayout);
