import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { login } from '../../../../Auth/actions/user-action';
import {
    Input,
    SubmitButton,
    Form,
} from 'formik-antd';
import './index.scss';
import { Icon, Typography } from 'antd';
import * as Yup from 'yup';
import { withRouter } from 'react-router';

const { Title } = Typography;


class FormContainer extends Component {
    render() {
        return (
            <Form className="login-form">
                <Title level={ 3 }>Admin</Title>
                <Form.Item name="email">
                    <Input
                        name="email" prefix={ <Icon type="user" style={ { color: 'rgba(0,0,0,.25)' } }/> }
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item name="password">
                    <Input
                        name="password"
                        prefix={ <Icon type="lock" style={ { color: 'rgba(0,0,0,.25)' } }/> }
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item name="n">
                    <SubmitButton disabled={ false } type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </SubmitButton>
                </Form.Item>
            </Form>
        );
    }
}

const AdminLoginForm = withFormik({
    mapPropsToValues(props) {
        return {
            email: props.email || '',
            password: props.password || '',
        };
    },
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email('Ingresa un correo válido.')
            .required('Ingresa tu correo.'),
        password: Yup.string()
            .required('Ingresa tu contraseña.'),
    }),
    handleSubmit(values, { props }) {
        const { email, password } = values;
        const { login, history } = props;

        // console.log(values);

        // TODO: check errors inside Redux
        login(email, password, history, true);
    },

})(FormContainer);


export default connect(null, { login })(withRouter(AdminLoginForm));
