import React, { Component } from 'react';
import './index.scss';

class SentimentChoice extends Component {

    handleChange = val => {
        const { updateValue, questionId, navigateSlider } = this.props;
        updateValue(questionId, 'value', val).then(() => {
            navigateSlider('next');
        });
    }

    render() {
        const { value } = this.props;
        const sentiments = ['angry', 'sad', 'neutral', 'okay', 'happy'];

        return (
            <div className="sentiment-choice">
                <div className="row">
                    <div className="col-md-1"></div>
                    { sentiments.map((e, i) =>
                    <div className="col-md-2" key={ i }>
                        <div
                            className={ `choice ${ e } ${ value === e ? 'selected' : '' }` }
                            onClick={ () => this.handleChange(e) }
                        ></div>
                    </div>
                    ) }
                </div>
            </div>
        );
    }
}

export default SentimentChoice;
