import React, { Component } from 'react';
import Input from '../../../Input';
import countries from './countries';

class PricingPlans extends Component {

    renderAddressSummary = (form, setStep) => {
        return (
            <div className="address-summary">
                <button
                    type="button"
                    className="btn-link"
                    onClick={ () => setStep('address') }
                >Edit</button>
                <p>
                    { form.billing.name }<br />
                    { form.billing.email }
                </p>
                <p>
                    { form.billing.address }<br />
                    { `${ form.address.city }, ${ form.address.state } ${ form.address.zip }` }<br />
                    { form.address.country }
                </p>
            </div>
        );
    }

    render() {
        const { form, step, setStep, updateValue } = this.props;

        return (
            <div className="checkout-wrapper pricing-address">
                <h3>Billing details</h3>
                { step === 'address' ?
                    <>
                        <Input
                            type="select"
                            title={ 'Country' }
                            options={ countries }
                            value={ form.address.country }
                            onChange={ e => updateValue('address', 'country', e.target.value) }
                        />
                        <Input
                            title={ 'Full name' }
                            value={ form.billing.name }
                            onChange={ e => updateValue('billing', 'name', e.target.value) }
                        />
                        <Input
                            title={ 'Email' }
                            value={ form.billing.email }
                            onChange={ e => updateValue('billing', 'email', e.target.value) }
                        />
                        <Input
                            type={ 'label' }
                            title={ 'Need invoice?' }
                        />
                        <Input
                            type="checkgroup"
                            title={ 'Do you need an invoice?' }
                            options={ [
                                {
                                    key: 'no',
                                    value: 'No',
                                },
                                {
                                    key: 'yes',
                                    value: 'Yes',
                                }
                            ] }
                            value={ form.billing.needInvoice }
                            onChange={ e => updateValue('billing', 'needInvoice', e) }
                        />
                        { form.billing.needInvoice === 'yes' &&
                            <Input
                                title={ 'Business name' }
                                value={ form.billing.businessName }
                                onChange={ e => updateValue('billing', 'businessName', e.target.value) }
                            />
                        }

                        { form.billing.needInvoice === 'yes' && form.address.country === 'MX' &&
                            <Input
                                title={ 'RFC' }
                                value={ form.billing.rfc }
                                onChange={ e => updateValue('billing', 'rfc', e.target.value) }
                            />
                        }

                        <hr />

                        <h3 className="margin-t30">Billing address</h3>

                        <Input
                            title={ 'Address' }
                            value={ form.address.address }
                            onChange={ e => updateValue('address', 'address', e.target.value) }
                        />
                        <Input
                            title={ 'City' }
                            value={ form.address.city }
                            onChange={ e => updateValue('address', 'city', e.target.value) }
                        />
                        <div className="row">
                            <div className="col-md-6">
                                <Input
                                    title={ 'State' }
                                    value={ form.address.state }
                                    onChange={ e => updateValue('address', 'state', e.target.value) }
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    title={ 'Zip code' }
                                    value={ form.address.zip }
                                    onChange={ e => updateValue('address', 'zip', e.target.value) }
                                />
                            </div>
                        </div>

                        <hr className="sm" />

                        <button
                            type="button"
                            className="btn-lg sm btn-gray"
                            onClick={ () => setStep("checkout") }
                        >Continue to payment details</button>
                    </>
                    : <>{ this.renderAddressSummary(form, setStep) }</>
                }
            </div>
        );
    }

}

export default PricingPlans;
