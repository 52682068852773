import React, { Component } from 'react';

class Summary extends Component {

    render() {
        const { form, updateValue } = this.props;

        return (
            <div className="payment-summary">
                <div className="summary-head">
                    Subscription
                </div>
                <div className="summary-content">
                    { form.billing.period === 'monthly' &&
                        <p>Swap to an annual plan to save 36% (that's over 4 months of Poupoll free).</p>
                    }
                    { form.billing.period === 'yearly' &&
                        <p>You're saving 36%</p>
                    }
                    <div className="divider"></div>
                    <div className="row">
                        <div className="col-sm-7">
                            <span className="subtotal">Subtotal</span>
                        </div>
                        <div className="col-sm-5">
                            <span className="subtotal-price">$49.00</span>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row">
                        <div className="col-sm-7">
                            <span className="total">Total</span>
                        </div>
                        <div className="col-sm-5">
                            <span className="total-price">$49.00</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Summary;
