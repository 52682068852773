import React from 'react';
import { Link } from 'react-router-dom';
import Input from '../Input';
import './index.scss';

class Login extends React.Component {
    state = {
        form: {
            email: null,
            password: null,
        },
    }

    updateValue = (key, value) => {
        const newState = { ...this.state };
        newState.form[key] = value;
        this.setState(newState);
    }

    onLogin = () => {
        // TODO: Display errors to user
        const { form: { email, password } } = this.state;
        const { login, history } = this.props;
        if (email.length > 0 && password.length > 0) {
            login(email, password, () => history.push('/panel/dashboard'))
        }
    }

    render() {
        const { showRegister } = this.props;
        const { form: { email, password } } = this.state;
        return (
            <>
                <div className="auth-title">Welcome back!</div>
                <div className="box">
                    <div className="text-sm block color-gray margin-b20">Enter your login info:</div>
                    <div className="margin-b20">
                        <Input title="Email address" value={ email }
                               onChange={ e => this.updateValue('email', e.target.value) }
                               style={ { marginRight: 0 } }/>
                    </div>
                    <div className="margin-b10">
                        <Input type="password" title="Password" value={ password }
                               onChange={ e => this.updateValue('password', e.target.value) }
                               style={ { marginRight: 0, marginBottom: 0 } }/>
                    </div>

                    <div className="block">
                        <Link to="/forgot-password" className="text-sm text-right block color-primary">Forgot your
                            password?</Link>
                    </div>

                    <button onClick={ this.onLogin } className="btn-lg btn-primary btn-auth">Login</button>
                </div>

                <div className="block text-center margin-t30">
                    <div className="text-sm block color-gray margin-b20">Don't have an account? <a
                        onClick={ () => showRegister() } className="text-sm color-primary" role="button"
                        tabIndex={ 0 }>Register</a></div>
                </div>

            </>
        );
    }
}

export default Login;