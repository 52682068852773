import React, { Component } from 'react';
import "./index.scss";

class BackgroundPosition extends Component {

    render() {
        const { updateValue, style: { backgroundPosition } } = this.props;

        return (
            <div className="bg-position-selector">
                <div
                    className={ `top-l ${ backgroundPosition === 'left top' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'left top') }
                ></div>
                <div
                    className={ `top-c ${ backgroundPosition === 'center top' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'center top') }
                ></div>
                <div
                    className={ `top-r ${ backgroundPosition === 'right top' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'right top') }
                ></div>

                <div
                    className={ `center-l ${ backgroundPosition === 'left center' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'left center') }
                ></div>
                <div
                    className={ `center-c ${ backgroundPosition === 'center center' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'center center') }
                ></div>
                <div
                    className={ `center-r ${ backgroundPosition === 'right center' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'right center') }
                ></div>

                <div
                    className={ `bottom-l ${ backgroundPosition === 'left bottom' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'left bottom') }
                ></div>
                <div
                    className={ `bottom-c ${ backgroundPosition === 'center bottom' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'center bottom') }
                ></div>
                <div
                    className={ `bottom-r ${ backgroundPosition === 'right bottom' ? 'selected' : '' }` }
                    onClick={ () => updateValue('style', 'backgroundPosition', 'right bottom') }
                ></div>
            </div>
        );
    }
}

export default BackgroundPosition;
