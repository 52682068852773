import React from 'react';
import RegisterForm from '../../components/RegisterForm';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { withRouter } from 'react-router-dom';
import config from '../../../../config';

class RegisterLayout extends React.Component {

    responseFacebook = (response) => {
        const { pathQuery, externalLogin, history } = this.props;

        if (response.id) {
            const { name, email, id } = response;
            const payload = { email, name, method: 'facebook', id };

            externalLogin(payload, pathQuery, history);
        } else {
            // TODO: Show error (Failed to login)
            console.log('Failed to login');
        }

    };

    responseGoogle = (response) => {
        const { pathQuery, externalLogin, history } = this.props;

        if (response.googleId) {
            const { w3, googleId } = response;

            const payload = {
                email: w3.U3,
                name: w3.ig,
                method: 'google',
                id: googleId,
            };

            externalLogin(payload, pathQuery, history);
        } else {
            // TODO: Handle fail login
            console.log('Failed to login');
        }
    };

    render() {
        const { pathQuery } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3" style={ { marginLeft: 'auto', marginRight: 'auto' } }>
                        REGISTRATE

                        <FacebookLogin
                            appId={ config.FACEBOOK_APP_ID }
                            fields="name,email,picture"
                            textButton={ 'Continuar con Facebook' }
                            callback={ this.responseFacebook }
                            cssClass="facebook-login-btn"
                            icon="fa-facebook"
                        />
                        <GoogleLogin
                            clientId={ config.GOOGLE_CLIENT_ID }
                            buttonText="Continuar con Google"
                            onSuccess={ this.responseGoogle }
                            onFailure={ this.responseGoogle }
                            className="google-login-btn"
                            cookiePolicy={ 'single_host_origin' }
                        />

                        <div className="auth-separator-container">
                            <div className="auth-separator"/>
                        </div>


                        <RegisterForm pathQuery={ pathQuery }/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RegisterLayout);
