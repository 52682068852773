import React, { Component } from 'react';
import Input from '../../components/PollInput';
import SentimentChoice from '../../components/SentimentChoice';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/custom-animations/fall-animation.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
// import '../../../../global.scss';
import './bootstrap.scss';
import './index.scss';

class PollLayout extends Component {

    state = {
        currentSlide: 1,
    }

    navigateSlider = action => {
        let { currentSlide } = this.state;
        const act = action === 'prev' ? currentSlide-1 : currentSlide+1;
        this.setState({ currentSlide: act });
        const ele = action === 'prev' ? 'awssld__prev' : 'awssld__next';
        document.getElementsByClassName(ele)[0].click();
    }

    render() {
        const {
            poll,
            questionList,
            style,
            updateValue,
            results,
        } = this.props;

        const { currentSlide } = this.state;

        const backgroundImage = `url(${ style.backgroundImage })`;

        const backgroundPosition = style.backgroundPosition ? style.backgroundPosition : 'inherit';

        let hasBackgroundStyle = style.backgroundType === 'image' ? { backgroundImage, backgroundPosition } : { };
        hasBackgroundStyle = style.backgroundType === 'color' ? { backgroundColor: style.backgroundColor } : hasBackgroundStyle;
        hasBackgroundStyle = style.backgroundType === null ? { backgroundColor: '#fff' } : hasBackgroundStyle;

        let animation = 'open'; // default
        switch(style.animation) {
            case 'cube': animation = 'cubeAnimation'; break; // works
            case 'fall': animation = 'fallAnimation'; break; // works
            case 'fold-out': animation = 'foldOutAnimation'; break; // works
            case 'open': animation = 'openAnimation'; break; // works
            case 'scale-out': animation = 'scaleOutAnimation'; break; // not sure
            default: animation = 'cubeAnimation';
        }

        return (
            <div className={ `survey ${ style.backgroundType === 'image' ? 'has-bg' : '' }` } style={ hasBackgroundStyle }>

                <AwesomeSlider
                    animation={ animation }
                    // animation="scaleOutAnimation"
                    bullets={ false }
                    organicArrows={ false }
                >
                    { questionList.map((e, i) =>
                        <div className="top-bg" style={ hasBackgroundStyle }>
                            <div className="box">
                                <h1>{ e.question }</h1>

                                { e.type === 'multiple-choice' &&
                                    <div className="list-options">
                                        <Input
                                            type="checkgroup"
                                            options={
                                                e.values.map(x => {
                                                    return {
                                                        key: x.id,
                                                        value: x.label
                                                    }
                                                })
                                            }
                                            questionId={ e.id }
                                            // key={ 'value' } // What value key we'll update on results
                                            value={ results.length ? results[i].value : null }
                                            updateValue={ updateValue }
                                            navigateSlider={ this.navigateSlider }
                                        />
                                    </div>
                                }

                                { e.type === 'sentiment-choice' &&
                                    <SentimentChoice
                                        questionId={ e.id }
                                        value={ results.length ? results[i].value : null }
                                        updateValue={ updateValue }
                                        navigateSlider={ this.navigateSlider }
                                    />
                                }
                            </div>
                        </div>
                    ) }
                </AwesomeSlider>

                <div className="control-bar">
                    { currentSlide !== 1 ?
                        <div className="arrow prev" onClick={ () => this.navigateSlider('prev') }>
                            <div className="prev-arrow"></div>
                        </div>
                        : <></>
                    }
                    { currentSlide !== questionList.length ?
                        <div className="arrow" onClick={ () => this.navigateSlider('next') }>
                            <div className="next-arrow"></div>
                        </div>
                        : <></>
                    }
                    <div className="question-count">{ currentSlide } / { questionList.length }</div>
                </div>
            </div>
        );
    }
}

export default PollLayout;
