import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
// import { connect } from 'react-redux';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PanelSidebar from "../../../Panel/Client/components/PanelSidebar";
import language from '../../../../lang';
// const { Content } = Layout;

class LayoutRoute extends Component {
    state = {
        lang: 'es',
    };

    async componentDidMount() {
        const lang = localStorage.getItem('language') || 'es';
        this.setState({ lang });
    }

    setLanguage = lang => {
        this.setState({ lang }, () => {
            localStorage.setItem('language', lang);
        });
    }

    render() {
        const { component: Component, authRoute, ...rest } = this.props;
        const { pathname } = this.props.location;
        let { lang } = this.state;

        const renderFunc = (
            <div>
                <Header lang={ lang } setLanguage={ this.setLanguage } langText={ language[lang]['header'] } />
                <Component lang={ lang } langText={ language[lang] } { ...this.props } />
                <Footer/>
            </div>
        );

        const authPanel = (
            <div className="panel">
                <PanelSidebar />
                <div className="panel-content" style={ { marginLeft: 220, minHeight: `${ pathname !== '/panel/dashboard' ? 'inherit' : '100vh' }`, } }>
                    <Component { ...this.props } />
                </div>
            </div>
        );

        const loginAdmin = (
            <Component { ...this.props } />
        );

        // TODO: Check for more efficient way?
        if (pathname === '/admin') {
            return <Route render={ () => loginAdmin } { ...rest } />;
        }

        if (authRoute === true) {
            return <Route render={ () => authPanel } { ...rest } />;
        }

        // Return React Router's route with render function
        return <Route render={ () => renderFunc } { ...rest } />;
    }
}

export default withRouter(LayoutRoute);
