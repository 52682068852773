import {
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_LOADING,
    USER_STOP_LOADING,
} from '../constants/action-types';
import axios from 'axios';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    loading: true,
    user: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
            };
        case USER_REGISTER_SUCCESS:
        case USER_LOGIN_SUCCESS:
            // Store for later use in headers
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                token: payload.token,
                isAuthenticated: true,
                user: payload.user,
                loading: false,
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true,
            };
        case USER_STOP_LOADING:
            return {
                ...state,
                loading: false,
            };
        case USER_REGISTER_FAIL:
        case AUTH_ERROR:
        case USER_LOGIN_FAIL:
        case USER_LOGOUT:
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
            };
        default:
            return state;
    }
};
