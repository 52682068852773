import React from 'react';
import './index.scss';

class Input extends React.Component {

    render() {
        const { title, onChange, options, value, style, type } = this.props;

        let theInput;

        switch (type) {
            case 'select':
                theInput = <select defaultValue={ value } onChange={ onChange } style={ style }>{ options.map((e, i) => <option key={ i } value={ e.value }>{ e.text }</option>) }</select>
                break;

            case 'textarea':
                theInput = <textarea onChange={ onChange } style={ style } required>{ value }</textarea>;
                break;

            case 'checkbox':
                return (
                    <div className={ `check ${ value ? 'checked' : '' }` } onClick={ () => onChange(!value) } style={ style }>
                        <div></div>
                        <span>{ title }</span>
                    </div>
                );

            case 'checkgroup':
                return (
                    <div className="checkgroup">
                        { options.map((e, i) => (
                            <div className={ `check-opt ${ e.key === value ? 'checked' : '' }` } onClick={ () => onChange(e.key) } key={ i } style={ style }>
                                { e.value }
                            </div>
                        )) }
                    </div>
                );

            case 'label':
                theInput = null;
                break;

            case 'password':
                theInput = <input type="password" value={ value } onChange={ onChange } style={ style } required />;
                break;

            default:
                theInput = <input type="text" value={ value } onChange={ onChange } style={ style } required />;
        }

        return (
            <div className="app-input">
                <label>{ title }</label>
                { theInput }
            </div>
        );
    }
}

export default Input;
