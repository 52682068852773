import React, { Component } from 'react';
import './index.scss';
import { Link } from "react-router-dom";
import Images from "../../../../assets/images";
import { withRouter } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import config from "../../../../config";
import GoogleLogin from "react-google-login";

class VerifyAuthLayout extends Component {

    responseFacebook = (response) => {
        const { pathQuery, externalLogin, history } = this.props;

        if (response.id) {
            const { name, email, id } = response;
            const payload = { email, name, method: 'facebook', id };

            externalLogin(payload, pathQuery, history);
        } else {
            // TODO: Handle fail login
            console.log('Failed to login');
        }

    };

    responseGoogle = (response) => {
        const { pathQuery, externalLogin, history } = this.props;

        if (response.googleId) {
            const { w3, googleId } = response;

            const payload = {
                email: w3.U3,
                name: w3.ig,
                method: 'google',
                id: googleId,
            };

            externalLogin(payload, pathQuery, history);
        } else {
            // TODO: Handle fail login
            console.log('Failed to login');
        }
    };

    render() {
        const { pathQuery } = this.props;
        return (
            <div className="auth-options">

                <div className="options-container">
                    <Link to={ `/login?form=${ pathQuery }` } className="options-title">Inicia sesión</Link>
                    <div className="options-text" style={ { marginBottom: 20 } }>o crea una cuenta nuevo con:</div>
                    <div className="new-account-options">

                        <GoogleLogin
                            clientId={ config.GOOGLE_CLIENT_ID }
                            onSuccess={ this.responseGoogle }
                            onFailure={ this.responseGoogle }
                            cookiePolicy={ 'single_host_origin' }
                            render={ renderProps => (
                                <img onClick={ renderProps.onClick } className="options-external google" alt="hand"
                                     src={ Images.verifyAuth.google }/>
                            ) }
                        />

                        <FacebookLogin
                            appId={ config.FACEBOOK_APP_ID }
                            fields="name,email,picture"
                            callback={ this.responseFacebook }
                            render={ renderProps => (
                                <img onClick={ renderProps.onClick } className="options-external fb" alt="hand"
                                     src={ Images.verifyAuth.facebook }/>
                            ) }
                        />

                        <Link to={ `/signup?form=${ pathQuery }` } className="options-list">Correo electrónico</Link>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(VerifyAuthLayout);
