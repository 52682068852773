import React, { Component } from 'react';
import './index.scss';

class PricingPlans extends Component {

    render() {
        const { setStep, updateValue } = this.props;
        return (
            <div className="pricing-plans">
                <div className="container">
                    <h1>Pricing that grows with you</h1>
                    <h4>Unlock all the features, cancel at any timex.</h4>
                    <div className="pricing-panel pricing-table">
                        <div className="pricing-plan">
                            {/* <img src="https://s22.postimg.cc/8mv5gn7w1/paper-plane.png" alt="" className="pricing-img" /> */}
                            <h2 className="pricing-header">Solo</h2>
                            <span className="pricing-price">$7</span>
                            <span className="pricing-period">per month</span>
                            <ul className="pricing-features">
                                <li className="pricing-features-item">50 responses per poll</li>
                                <li className="pricing-features-item">Powerful statistics</li>
                                <li className="pricing-features-item">Export your results</li>
                                <li className="pricing-features-item">Custom logo and branding colors</li>
                            </ul>
                            <button
                                onClick={ () => { updateValue(null, 'plan', 'solo'); setStep('address'); } }
                                className="pricing-button"
                            >Select</button>
                        </div>
                        <div className="pricing-plan">
                            {/* <img src="https://s28.postimg.cc/ju5bnc3x9/plane.png" alt="" className="pricing-img" /> */}
                            <h2 className="pricing-header">Small team</h2>
                            <span className="pricing-price">$29</span>
                            <span className="pricing-period">per month</span>
                            <ul className="pricing-features">
                                <li className="pricing-features-item">300 responses per poll</li>
                                <li className="pricing-features-item">Powerful statistics</li>
                                <li className="pricing-features-item">Export your results</li>
                                <li className="pricing-features-item">Custom logo and branding</li>
                                <li className="pricing-features-item">Create your own design and add images</li>
                                <li className="pricing-features-item">Sentiment analysis</li>
                                <li className="pricing-features-item">Up to 7 team members</li>
                            </ul>
                            <button
                                onClick={ () => { updateValue(null, 'plan', 'team'); setStep('address'); } }
                                className="pricing-button is-featured"
                            >Select</button>
                        </div>
                        <div className="pricing-plan">
                            {/* <img src="https://s21.postimg.cc/tpm0cge4n/space-ship.png" alt="" className="pricing-img" /> */}
                            <h2 className="pricing-header">Enterprise</h2>
                            <span className="pricing-price">$79</span>
                            <span className="pricing-period">per month</span>
                            <ul className="pricing-features">
                                <li className="pricing-features-item"><b>Unlimited responses</b></li>
                                <li className="pricing-features-item">Powerful statistics</li>
                                <li className="pricing-features-item">Export your results</li>
                                <li className="pricing-features-item">Custom logo and branding</li>
                                <li className="pricing-features-item">Create your own design and add images</li>
                                <li className="pricing-features-item">Sentiment analysis</li>
                                <li className="pricing-features-item">Add unlimited team members</li>
                            </ul>
                            <button
                                onClick={ () => { updateValue(null, 'plan', 'enterprise'); setStep('address'); } }
                                className="pricing-button"
                            >Select</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default PricingPlans;
