export default {
    es: {
        common: {
            continuar: 'Continuar',
            regresar: 'Regresar',
        },
        header: {
            inicio: 'Inicio',
            armaTuKit: '¡Arma tu Kit!',
            productos: 'Productos',
            regalos: 'Regalos',
            comoFunciona: '¿Cómo funciona?',
            nosotros: 'Nosotros',
            contacto: 'Contacto',
        },
        footer: {
            siguenos: 'Síguenos',
            legales: 'Legales',
            acerca: 'Acerca de',
            soporte: 'Soporte',
            suscribirse: 'Suscribirse',
        },
        home: {
            nuestrasMarcas: 'Nuestras marcas',
            nuestrasMarcasDesc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda commodi cumque delectus in ut vitae!',
            nuestrosConsejos: 'Sigue nuestros consejos',
            nuestrosConsejosDesc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda commodi cumque delectus in ut vitae!',
            topProductos: 'Top 10 mejores productos',
            topProductosDesc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda commodi cumque delectus in ut vitae!',
        },
        kit: {
            titulo: 'Arma tu kit',
            comencemos: `¡COMENCEMOS!`,
            subtitulo: `Primero hay que elegir el kit que se adapte a lo que necesitas:`,
            principal: {
                individual: 'INDIVIDUAL',
                yoSoy: 'Yo soy',
                pareja: 'PAREJA',
                miParejaEs: 'Mi pareja es',
                familiar: 'FAMILIAR',
                tienesHijos: '¿Tienes hijos?',
                si: 'Si',
                hombre: 'Hombre',
                mujer: 'Mujer',
                noTengo: 'No tengo pareja',
                anos: 'años',
            },
        },
        productos: {
            cuidadoDelCabello: 'Cuidado del cabello',
            cuidadoDeLaPiel: 'Cuidado de la piel',
            cuidadoDeLaBarba: 'Cuidado de la barba',
            aromaterapia: 'Aromaterapia',
        },
        carrito: {
            titulo: 'TU CARRITO',
            subtitulo: '¡Aquí llevas todo lo necesario para tener una piel y cabello sanos! ¿No olvidas nada?',
            articulos: 'Artículos',
        },
        comoFunciona: {
            titulo: '¿CÓMO FUNCIONA EL KIT?',
            subtitulo: 'Queremos que no te preocupes cada vez que se terminan tos productos del cuidado de la piel y el cabello. Nos encargaremo de entregarte todo lo que necesitas, ¡siempre!',
        },
    },
    en: {
        common: {
            continuar: 'Continue',
            regresar: 'Go back',
        },
        header: {
            inicio: 'Home',
            armaTuKit: 'Build your kit!',
            productos: 'Products',
            regalos: 'Gifts',
            comoFunciona: 'How it works?',
            nosotros: 'About us',
            contacto: 'Contact',
        },
        footer: {
            siguenos: 'Follow us',
            legales: 'Legals',
            acerca: 'About',
            soporte: 'Support',
            suscribirse: 'Subscribe',
        },
        home: {
            nuestrasMarcas: 'Our brands',
            nuestrasMarcasDesc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda commodi cumque delectus in ut vitae!',
            nuestrosConsejos: 'Follow our advice',
            nuestrosConsejosDesc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda commodi cumque delectus in ut vitae!',
            topProductos: 'Top 10 best products',
            topProductosDesc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda commodi cumque delectus in ut vitae!',
        },
        kit: {
            titulo: 'Build your kit',
            comencemos: `LET'S GET STARTED!`,
            subtitulo: `First you must select the kit that adapts to your needs:`,
            principal: {
                individual: 'INDIVIDUAL',
                yoSoy: 'I am',
                pareja: 'COUPLE',
                miParejaEs: 'My couple is',
                familiar: 'FAMILY',
                tienesHijos: 'Have children?',
                si: 'Yes',
                hombre: 'Man',
                mujer: 'Woman',
                noTengo: `I don't have couple`,
                anos: 'years',
            },
        },
        productos: {
            cuidadoDelCabello: 'Hair care',
            cuidadoDeLaPiel: 'Skin care',
            cuidadoDeLaBarba: 'Beard care',
            aromaterapia: 'Aromatherapy',
        },
        carrito: {
            titulo: 'YOUR CART',
            subtitulo: `Here you have everything you need to have healthy skin and hair! Don't you forget anything?`,
            articulos: 'Articles',
        },
        comoFunciona: {
            titulo: 'HOW DOES THE KIT WORK?',
            subtitulo: 'We want you not to worry every time you finish your skin and hair care products. We will take care of delivering everything you need, always!',
        },
    },
};
