import React, { Component } from 'react';
import Address from './Address';
import Summary from './Summary';
import PaymentDetails from './PaymentDetails';

class Checkout extends Component {

    render() {
        const { form, step, setStep, updateValue } = this.props;

        return (
            <div className="checkout">
                <div className="row">
                    <div className="col-md-6">
                        <Address
                            form={ form }
                            step={ step }
                            setStep={ setStep }
                            updateValue={ updateValue }
                        />
                        { step === 'checkout' &&
                            <PaymentDetails
                                form={ form }
                                updateValue={ updateValue }
                            />
                        }
                    </div>

                    <div className="col-md-5 col-md-offset-1">
                        <Summary form={ form } updateValue={ updateValue} />
                    </div>
                </div>
            </div>
        );
    }

}

export default Checkout;
