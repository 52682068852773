import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SlidingPane from 'react-sliding-pane';
import NewProperty from '../../components/NewPayment';
import 'react-sliding-pane/dist/react-sliding-pane.css';
// import assets from '../../../../assets/images';
import '../../../css/index.scss';

const listings = {
    results:[
        { id: 911460281, 'name':'Daniel Gutierrez', 'address':'Ignacio Jáuregui #1383' },
        { id: 125360210, 'name':'Valentín Elizalde', 'address':'Del Dios #451' },
        { id: 125360210, 'name':'Ernesto Gregorio', 'address':'Volcán Cofre de Perote #331' },
    ],
};

class PaymentsLayout extends Component {

    state = {
        isPaneOpen: true,
    }

    render() {
        const { isPaneOpen } = this.state;

        return (
            <div className="description wrapper">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <h1>Pagos<button className="btn-md btn-primary" onClick={ () => this.setState({ isPaneOpen: true }) }>Recibir pagos de renta</button></h1>

                        <input type="text" className="filter" placeholder="Filtrar resultados..." />

                        <ul className="property-list">
                            { listings.results.map((listing, i) =>
                                <li className="row" key={ i }>
                                    <div className="col-md-12">
                                        <div className="info">
                                            <div className="name">{ listing.address }</div>
                                            <div className="type">Departamento</div>
                                        </div>
                                    </div>
                                </li>
                            ) }
                        </ul>
                    </div>
                </div>

                <SlidingPane
                    className={ 'slide-pane' }
                    overlayClassName={ 'some-custom-overlay-class' }
                    isOpen={ isPaneOpen }
                    // isOpen={ true }
                    from={ 'right' }
                    width={ '90%' }
                    title={ 'Recibir pago de renta' }
                    onRequestClose={ () => {
                        this.setState({ isPaneOpen: false });
                    } }>
                    <NewProperty />
                </SlidingPane>
            </div>
        );
    }
}

export default withRouter(PaymentsLayout);
