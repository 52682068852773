import React, { Component } from 'react';
import './index.scss';

class Rating extends Component {

    handleChange = e => {
        const { updateValue } = this.props;
        updateValue('value', e);
    }

    render() {
        const { value } = this.props;
        console.log('STAR value', value);

        return (
            <div className="row rating">
                { [5,4,3,2,1].map((e, i) =>
                    <>
                        <input
                            type="radio"
                            name="rating"
                            id={ `rating-${ e }` }
                            value={ e }
                            onChange={ e => this.handleChange(e.target.value) }
                            key={ i }
                            checked={ value === String(e) }
                        />
                        <label className="col-md-1" htmlFor={ `rating-${ e }` }></label>
                    </>
                ) }
            </div>
        );
    }
}

export default Rating;
