import React, { Component } from 'react';
import Input from '../../../../../Core/components/Input';
import BackgroundSection from './Background';
import PreviewSection from './Preview';
import FontSection from './Font';
import TransitionSection from './Transition';
import './index.scss';

class StyleContainer extends Component {

    render() {
        const { updateValue, style, changeViewport } = this.props;

        return (
            <ul className="style-parent">
                <li className="style-section">
                    <input type="checkbox" />
                    <i></i>
                    <div className="style-title">Preview</div>
                    <div className="style-content">
                        <PreviewSection
                            changeViewport={ changeViewport }
                        />
                    </div>
                </li>
                <li className="style-section">
                    <input type="checkbox" />
                    <i></i>
                    <div className="style-title">Fonts &amp; colors</div>
                    <div className="style-content">
                        <FontSection
                            updateValue={ updateValue }
                            style={ style }
                        />
                    </div>
                </li>
                <li className="style-section background-section">
                    <input type="checkbox" />
                    <i></i>
                    <div className="style-title">Background</div>
                    <div className="style-content">
                        <BackgroundSection
                            updateValue={ updateValue }
                            style={ style }
                        />
                    </div>
                </li>
                <li className="style-section background-section">
                    <input type="checkbox" />
                    <i></i>
                    <div className="style-title">Transition</div>
                    <div className="style-content">
                        <TransitionSection
                            updateValue={ updateValue }
                            style={ style }
                        />
                    </div>
                </li>
            </ul>
        );
    }
}

export default StyleContainer;
