import React, { Component } from 'react';
import './index.scss';

class PreviewContainer extends Component {

    render() {
        const { changeViewport } = this.props;

        return (
            <div className="preview-container">
                <div className="mobile" onClick={ () => changeViewport("mobile") }></div>
                <div className="tablet" onClick={ () => changeViewport("tablet") }></div>
                <div className="laptop" onClick={ () => changeViewport("laptop") }></div>
            </div>
        );
    }
}

export default PreviewContainer;
