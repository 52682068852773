import React, { Component } from 'react';
import moment from 'moment/locale/es'; // Set locale for react-dates
import momentD from 'moment'; // Set locale for react-dates
import Input from '../../../../Core/components/Input';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-upload-gallery/dist/style.css' // or scss
import './index.scss';

class NewPayment extends Component {

    state = {
        step: 2,
        focusedLeaseFrom: false,
        focusedLeaseEnd: false,
        focusedChargeDay: false,
        form: {
            location: {
                address: null,
                extNo: null,
                zipCode: null,
            },
            payment: {
                month2month: null,
                amount: null,
                leaseFrom: null,
                leaseEnd: null,
                chargeDay: null,
            },
        },
    };

    nextStep = () => {
        // const { step } = this.state;
        // TODO: Validate
        this.setState({ step: this.state.step + 1 }, () => {
            setTimeout(() => {
                // window.scrollTo(0, 1500);
                document.querySelector('.slide-pane__content').scrollTop = document.querySelector('.slide-pane__content').scrollHeight
            }, 100);
        });
    }

    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    }

    updateValue = (step, key, value) => {
        const newState = { ...this.state };
        newState.form[step][key] = value;
        this.setState(newState, () => {
            console.log('newState...', newState);
        });
    };

    render() {
        const {
            step,
            focusedLeaseFrom,
            focusedLeaseEnd,
            focusedChargeDay,
            form,
            form: {
                payment: { amount, leaseFrom, leaseEnd, chargeDay }
            },
        } = this.state;

        return (
            <div className="row new-property">
                <div className="col-md-8 col-md-offset-2">

                    <div className={ `step-one ${ step !== 1 && 'blur' }` }>
                        <div className="section-title">Ubicación</div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="margin-b20">
                                        <Input title="Dirección" value={ form.location.address } onChange={ e => this.updateValue('location', 'address', e.target.value) } />
                                    </div>
                                    <div className="margin-b20">
                                        <Input title="# Exterior" value={ form.location.extNo } onChange={ e => this.updateValue('location', 'extNo', e.target.value) } />
                                    </div>
                                    <div className="margin-b20">
                                        <Input title="Código Postal" value={ form.location.zipCode } onChange={ e => this.updateValue('location', 'zipCode', e.target.value) } />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={ `block text-center margin-t30 ${ step != 1 && 'hidden' }` }>
                        <button type="button" className="btn-md btn-primary" onClick={ () => this.nextStep() }>Continuar</button>
                    </div>

                    <hr className={ `${ step < 2 && 'hidden' }` } />

                    <div className={ `step-two ${ step !== 2 && 'blur' } ${ step < 2 && 'hidden' }` }>
                        <div className="section-title">Acerca de la renta</div>

                        <Input type="label" title="¿Qué tipo de contrato de renta manejas?" />

                        <Input type="checkgroup" options={ [
                            {
                                key: 'si',
                                value: 'Plazo indefinido',
                            },
                            {
                                key: 'no',
                                value: 'Plazo definido',
                            },
                        ] } value={ form.payment.month2month } onChange={ e => this.updateValue('payment', 'month2month', e) } />

                        { form.payment.month2month &&
                            <>
                                <Input type="label" title="¿Cuándo comienza a correr el contrato?" />

                                <SingleDatePicker
                                    placeholder=""
                                    date={ leaseFrom }
                                    onDateChange={ e => this.updateValue('payment', 'leaseFrom', e) } // PropTypes.func.isRequired
                                    id="datepick" // PropTypes.string.isRequired,
                                    focused={ focusedLeaseFrom } // PropTypes.bool
                                    onFocusChange={ ({ focused }) => this.setState({ focusedLeaseFrom: focused }) }
                                />
                            </>
                        }

                        <div className="block margin-b20"></div>

                        { form.payment.month2month && (
                            ( form.payment.month2month === 'si' ? (
                                <>
                                    <Input type="label" title="¿Qué día comenzarían los cargos de renta recurrentes?" />
                                    <SingleDatePicker
                                        placeholder=""
                                        date={ chargeDay }
                                        onDateChange={ e => this.updateValue('payment', 'chargeDay', e) } // PropTypes.func.isRequired
                                        id="datepick2" // PropTypes.string.isRequired,
                                        focused={ focusedChargeDay } // PropTypes.bool
                                        onFocusChange={ ({ focused }) => this.setState({ focusedChargeDay: focused }) }
                                    />
                                </>
                            )
                                : ( <>
                                    <Input type="label" title="¿Cuándo finaliza el contrato de renta?" />
                                    <SingleDatePicker
                                        placeholder=""
                                        date={ leaseEnd }
                                        onDateChange={ e => this.updateValue('payment', 'leaseEnd', e) } // PropTypes.func.isRequired
                                        id="datepick3" // PropTypes.string.isRequired,
                                        focused={ focusedLeaseEnd } // PropTypes.bool
                                        onFocusChange={ ({ focused }) => this.setState({ focusedLeaseEnd: focused }) }
                                    />
                                </> )
                            )
                        ) }

                        <div className="block margin-b20"></div>
                    </div>

                    <div className={ `block text-center margin-t30 ${ step != 2 && 'hidden' }` }>
                        <button type="button" className="btn-md btn-primary" onClick={ () => this.nextStep() }>Continuar</button>
                    </div>

                    <hr className={ `${ step < 3 && 'hidden' }` } />

                    <div className={ `step-two ${ step !== 3 && 'blur' } ${ step < 3 && 'hidden' }` }>
                        <div className="section-title">Renta mensual</div>

                        <div className="row">
                            <div className="col-md-6">
                                <Input title={ `Cantidad mensual` } value={ form.payment.amount } onChange={ e => this.updateValue('place', 'amount', e.target.value) } />
                            </div>
                        </div>
                    </div>

                    <div className={ `block text-center margin-t30 ${ step != 3 && 'hidden' }` }>
                        <button type="button" className="btn-md btn-primary" onClick={ () => this.nextStep() }>Continuar</button>
                    </div>

                    <hr className={ `${ step < 4 && 'hidden' }` } />

                    <div className={ `step-two ${ step !== 4 && 'blur' } ${ step < 4 && 'hidden' }` }>
                        <div className="section-title">Revisa la información y enviar</div>

                        <p>Por favor, revisa la información a continuación y ten en cuenta que una vez que tu 
                            inquilino acepte la invitación, ya no podrás realizar cambios en la fecha de vencimiento
                            ni en la duración del contrato de renta.</p>

                        <div className="block margin-b30"></div>

                        <div className="review-row">
                            <div className="img time"></div>
                            <div>
                                <div className="review-txt">Contrato de renta comienza el { momentD(form.payment.leaseFrom).isValid() ? momentD(form.payment.leaseFrom).format('d M Y') : '' }</div>
                            </div>
                        </div>
                        <div className="review-row">
                            <div className="img cash"></div>
                            <div>
                                <div className="review-txt">{ `$${ form.payment.amount } /mes`}</div>
                            </div>
                        </div>
                        <div className="review-row">
                            <div className="img cal"></div>
                            <div>
                                <div className="review-txt">{ `Se pagan los días ${ form.payment.chargeDay } de cada mes`}</div>
                            </div>
                        </div>
                    </div>

                    <div className={ `block text-center margin-t30 ${ step != 4 && 'hidden' }` }>
                        <button type="button" className="btn-big btn-primary" onClick={ () => this.nextStep() }>Enviar invitación por cargo recurrente</button>
                    </div>

                </div>
            </div>
        );
    }
}

export default NewPayment;
