import React from 'react';
import Carousel from '@brainhubeu/react-carousel';
import moment from 'moment';
import '@brainhubeu/react-carousel/lib/style.css';
import './index.scss';

class ScheduleWidget extends React.Component {

    state = {
        index: 0,
    };

    getDaysArray = () => {
        const day1 = moment("2019-05-01");
        const day2 = moment("2019-04-06");
        // const day1 = moment();
        // const day2 = moment().add(1, 'M');
        const dates = [moment({ ...day2 })];

        while (day1.date() != day2.date()) {
            day2.add(1, 'day');
            dates.push(moment({ ...day2 }));
        }

        return dates;
    }

    capitalize = word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    render() {
        const { index } = this.state;

        const daysBetween = this.getDaysArray();

        console.log('daysBetween', daysBetween);

        return (
            <div className="row widget">
                <div className="tour-header">
                    <div className="img"></div>
                    <span>Fecha del tour</span>
                </div>

                <Carousel
                    slidesPerPage={ 2 }
                    arrows
                    infinite
                >
                    { daysBetween.map((e, i) => (
                        <div className="item" key={ i }>
                            <div className="inner">
                                <div className="dof">{ this.capitalize(e.format('ddd')).replace('.', '') }</div>
                                <div className="date">{ e.format('D') }</div>
                                <div className="">{ this.capitalize(e.format('MMM')).replace('.', '') }</div>
                            </div>
                        </div>
                    ))}
                </Carousel>

                <button className="btn-lg btn-primary btn-widget margin-t30">Agendar tour</button>

            </div>
        );
    }
}

export default ScheduleWidget;