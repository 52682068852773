import React from 'react';
import LoginLayout from '../../layouts/Login';
import { connect } from 'react-redux';
import { externalLogin } from '../../actions/user-action';
import * as queryString from 'query-string';

class Login extends React.Component {
    render() {
        const parse = queryString.parse(this.props.location.search);
        const { externalLogin } = this.props;
        const pathQuery = parse.form;

        return (
            <LoginLayout pathQuery={ pathQuery } externalLogin={ externalLogin }/>
        );
    }
}

export default connect(null, { externalLogin })(Login);
