import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AccountLayout from '../../layouts/Account';

class Account extends Component {

    state = {
    }

    componentDidMount() {
        //
    }

    render() {
        return (
            <AccountLayout />
        );
    }
}

export default withRouter(Account);
