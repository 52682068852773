import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardElement,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import Input from '../../../Input';
import axios from 'axios';

const stripe = loadStripe("pk_test_51H7sBBIWLntmvLG0lDHKJdZc6qcB1eqSWEPA9Ly2U3xMligJxPxO7gYr12oUB5sPFd4BZQQwd10Zdwakdvs3OlRf00BuUZ0i8V");

class CheckoutForm extends React.Component {
    state = {
        errorMsg: null,
    }

    handleSubmit = async () => {
        console.log('clicked')
        // e.preventDefault();
        const { stripe, elements } = this.props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (!paymentMethod) return false;

        console.log('Payment/card error', error);
        console.log('Payment/card result', paymentMethod);

        // If error
        if(paymentMethod.type === "validation_error") {
            this.setState({ errorMsg: paymentMethod.message });
            return false;
        }

        const { id, card: { exp_month, exp_year, last4, brand } } = paymentMethod;
        const data = {
            id, exp_month, exp_year, last4, brand,
        };
        // TODO: Store in back the data Obj
        // axios.post(url, data)
        //     .then(function (response) {
        //         console.log(response);
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }
  
    render() {
      const { stripe, form } = this.props;
      const { errorMsg } = this.state;
      return (
        <>
            <form className="stripe-form-style">
                <CardElement
                    value={ { postalCode: form.address.zip } }
                />
                { errorMsg && <span className="error-msg">{ errorMsg }</span> }
            </form>
            <div className="btn-parent">
                <button
                    type="submit"
                    disabled={ !stripe }
                    className="btn-lg sm btn-primary"
                    onClick={ () => this.handleSubmit() }
                >Pay now</button>
            </div>
        </>
      );
    }
}

class PaymentDetails extends Component {

    render() {
        const { form, updateValue } = this.props;

        return (
            <div className="checkout-wrapper">
                <h3 className="margin-t30">Payment details</h3>

                <Input
                    type="text"
                    title={ 'Full name' }
                    value={ form.billing.country }
                    onChange={ e => updateValue('billing', 'country', e.target.value) }
                />
                
                <Elements stripe={stripe}>
                    <ElementsConsumer>
                        {({stripe, elements}) => (
                            <CheckoutForm stripe={ stripe } elements={ elements } form={ form } />
                        )}
                    </ElementsConsumer>
                </Elements>
            </div>
        );
    }
}

export default PaymentDetails;
