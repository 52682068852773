import React, { Component } from 'react';
import DashboardLayout from '../../layouts/Dashboard';

class Dashboard extends Component {
    render() {
        return (
            <DashboardLayout />
        );
    }
}

export default Dashboard;
