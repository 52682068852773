import React, { Component } from 'react';
import ImageUploader from "react-images-upload";
import Input from '../../../../../Core/components/Input';
import './index.scss';

class IntroScreen extends Component {

    state = {
        pictures: [],
        showMenu: false,
    }

    onDrop = (pictureFiles, pictureDataURLs) => {
        const { updateValue } = this.props;

        // Reset pictures arr first (only 1 img is allowed)
        this.setState({ pictures: [] });
        ///

        console.log('pictureFiles', pictureFiles);
        console.log('pictureDataURLs', pictureDataURLs);

        this.setState({
            pictures: pictureFiles,
            // pictureLocalURL: pictureDataURLs[pictureDataURLs.length - 1],
        }, () => console.log(this.state.pictures));

        updateValue('introData', 'image', pictureDataURLs[pictureDataURLs.length - 1]);
    }

    handleAutogrowChange = ev => { // Autogrow text areas
        const el = ev.target;
        el.style.height = el.setAttribute('style', `height: ${ el.scrollHeight + 4 }px`);
        el.classList.add('auto');
        el.addEventListener('input', e => {
            el.style.height = 'auto';
            el.style.height = `${ el.scrollHeight + 4 }px`;
        });
    }

    toggleMenu = () => this.setState({ showMenu: !this.state.showMenu });

    render() {
        const { updateValue, introData } = this.props;
        const { showMenu } = this.state;

        let style = {};

        style.backgroundImage = introData.image ? `url(${ introData.image })` : 'none';
        style.backgroundSize = introData.imageSize;
        style.backgroundPosition = introData.imagePosition;
        style.backgroundRepeat = introData.imageRepeat;

        return (
            <div className="options-box intro-screen">

                <div className="image-uploader-wrapper">
                    <div className="cog" onClick={ () => this.toggleMenu() }></div>
                    <ImageUploader
                        buttonText="Choose image"
                        onChange={ this.onDrop }
                        imgExtension={ [".jpg", ".png"] }
                        maxFileSize={ 5242880 }
                        className={ `image-preview ${ introData.imageType } ${ introData.image ? 'has-img' : '' }` }
                        fileContainerStyle={ style }
                        withIcon={ introData.image ? false : true }
                        label={ introData.image ? null : 'Max file size: 5mb, accepted: jpg, png'	 }
                        singleImage
                    />

                    { showMenu &&
                        <div className="image-options-menu">
                            <div className="row">
                                <div className="col-xs-4"><span className="menu-title">Type</span></div>
                                <div className="col-xs-8">
                                <select value={ introData.imageType } onChange={ e => updateValue('introData', 'imageType', e.target.value) }>
                                        <option value="circle">Circle</option>
                                        <option value="square">Square</option>
                                        <option value="full-width">Full-width</option>
                                    </select>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="row">
                                <div className="col-xs-4"><span className="menu-title">Size</span></div>
                                <div className="col-xs-8">
                                    <select value={ introData.imageSize } onChange={ e => updateValue('introData', 'imageSize', e.target.value) }>
                                        <option value="contain">Contain</option>
                                        <option value="cover">Cover</option>
                                    </select>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="row">
                                <div className="col-xs-4"><span className="menu-title">Position</span></div>
                                <div className="col-xs-8">
                                    <select value={ introData.imagePosition } onChange={ e => updateValue('introData', 'imagePosition', e.target.value) }>
                                        <option value="left top">Left-Top</option>
                                        <option value="center top">Center-Top</option>
                                        <option value="right top">Right-Top</option>
                                        <option value="left center">Left-Center</option>
                                        <option value="center center">Center-Center</option>
                                        <option value="right center">Right-Center</option>
                                        <option value="left bottom">Left-Bottom</option>
                                        <option value="center bottom">Center-Bottom</option>
                                        <option value="right bottom">Right-Bottom</option>
                                    </select>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="row">
                                <div className="col-xs-4"><span className="menu-title">Repeat</span></div>
                                <div className="col-xs-8">
                                    <select value={ introData.imageRepeat } onChange={ e => updateValue('introData', 'imageRepeat', e.target.value) }>
                                        <option value="repeat">Repeat</option>
                                        <option value="no-repeat">No Repeat</option>
                                        <option value="repeat-x">Repeat X axis</option>
                                        <option value="repeat-y">Repeat Y axis</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }

                </div>
                <textarea
                    rows="1"
                    placeholder="Add a welcome title"
                    className="intro-title autogrow"
                    value={ introData.title }
                    onChange={ e => { this.handleAutogrowChange(e); updateValue('introData', 'title', e.target.value) } }
                />
                <textarea
                    rows="1"
                    placeholder="Click to add a welcome description..."
                    className="intro-desc autogrow"
                    value={ introData.description }
                    onChange={ e => { this.handleAutogrowChange(e); updateValue('introData', 'description', e.target.value) } }
                />
                <div className="block text-center margin-t30">
                    <div className="intro-btn btn-md">
                        <input type="text" value={ introData.btnTitle } onChange={ e => updateValue('introData', 'btnTitle', e.target.value) } />
                    </div>
                </div>
            </div>
        );
    }
}

export default IntroScreen;
