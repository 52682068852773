import React from 'react';
import { connect } from 'react-redux';
import { register } from '../../actions/user-action';
import { Link, withRouter } from 'react-router-dom';
import { Form, Field, withFormik } from 'formik';
import * as Yup from 'yup';

class FormContainer extends React.Component {

    render() {
        return (
            <Form className="auth-form">
                <div className="block">
                    <Field name="email" className="form-input auth-input" placeholder="email" type="text"/>
                </div>
                <div className="block">
                    <Field name="password" className="form-input auth-input" placeholder="password" type="password"/>
                </div>

                <button className="auth-btn" type="submit">
                    Crear cuenta
                </button>
                <div className="block">
                    <Link to="/login" style={ { marginTop: 12, display: 'block' } }>¿Ya tienes cuenta?</Link>
                </div>
            </Form>
        );
    }
}

const RegisterForm = withFormik({
    mapPropsToValues(props) {
        return {
            email: props.email || '',
            password: props.password || '',
        };
    },
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email('Ingresa un correo válido.')
            .required('Ingresa tu correo.'),
        password: Yup.string()
            .required('Ingresa tu contraseña.'),
    }),
    handleSubmit(values, { props }) {
        const { email, password } = values;
        const { register, history, pathQuery } = props;

        // TODO: check errors inside Redux
        register(email, password, history, pathQuery);
    },

})(FormContainer);


export default connect(null, { register })(withRouter(RegisterForm));
