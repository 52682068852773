import random from './random';
import core from './core';
import home from './home';
import payment from './payment';
import product from './product';
import verifyAuth from './verifyAuth';

export default {
    random,
    core,
    home,
    payment,
    product,
    verifyAuth,
};
