import React, { Component } from 'react';
import Input from '../../../../Core/components/Input';
import './index.scss';

class About extends Component {

    state = {
        form: {
            name: 'Daniel Gutiérrez',
            email: 'gtzgileta@gmail.com',
            mobile: '33 1145 2532',
            phone: '33 1984 3028',
        },
    };

    updateValue = (key, value) => {
        const newState = { ...this.state };
        newState.form[key] = value;
        this.setState(newState, () => {
            console.log('newState...', newState);
        });
    };

    render() {
        const {
            form: {
                name, email, mobile, phone,
            },
        } = this.state;

        return (
            <div className="row new-property">
                <div className="col-md-8 col-md-offset-2">

                    {/* <div className="section-title">About me</div> */}

                    <div className="row">
                        <div className="margin-b20">
                            <Input title="Name" value={ name } onChange={ e => this.updateValue('name', e.target.value) } />
                        </div>
                        <div className="margin-b20">
                            <Input title="Email address" value={ email } onChange={ e => this.updateValue('email', e.target.value) } />
                        </div>
                        <div className="margin-b20">
                            <Input title="Cellphone" value={ mobile } onChange={ e => this.updateValue('mobile', e.target.value) } />
                        </div>
                    </div>

                    <div className={ `block text-center margin-t30` }>
                        <button type="button" className="btn-big btn-primary" onClick={ () => this.nextStep() }>Save</button>
                    </div>

                </div>
            </div>
        );
    }
}

export default About;
