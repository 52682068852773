import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './index.scss';

class Gallery extends React.Component {

    state = {
        photoIndex: 0,
        isOpen: false,
    };

    render() {
        let {
            mainImage, secondImage, thirdImage, fourthImage, fifthImage,
        } = this.props;
        const { isOpen, photoIndex } = this.state;

        const mainImageCSS = `url(${ mainImage })`;
        const secondImageCSS = `url(${ secondImage })`;
        const thirdImageCSS = `url(${ thirdImage })`;
        const fourthImageCSS = `url(${ fourthImage })`;
        const fifthImageCSS = `url(${ fifthImage })`;

        const images = [mainImage, secondImage, thirdImage, fourthImage, fifthImage];

        return (
            <div className="row gallery">
                <div className="col-md-6 left">
                    <div className="img" style={ { backgroundImage: mainImageCSS } }></div>
                </div>
                <div className="col-md-6 right">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="img" style={ { backgroundImage: secondImageCSS } }></div>
                        </div>
                        <div className="col-sm-6">
                            <div className="img tr" style={ { backgroundImage: thirdImageCSS } }></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="img" style={ { backgroundImage: fourthImageCSS } }></div>
                        </div>
                        <div className="col-sm-6">
                            <div className="img br" style={ { backgroundImage: fifthImageCSS } }></div>
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    className="btn-sm btn-white floating-button"
                    onClick={ () => this.setState({ isOpen: true }) }
                >Ver todas las fotos</button>


                { isOpen && (
                    <Lightbox
                        mainSrc={ images[photoIndex] }
                        nextSrc={ images[(photoIndex + 1) % images.length] }
                        prevSrc={ images[(photoIndex + images.length - 1) % images.length] }
                        onCloseRequest={ () => this.setState({ isOpen: false }) }
                        onMovePrevRequest={ () =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={ () =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}

export default Gallery;