// AUTH
export const AUTH_DATA_LOAD = 'AUTH_DATA_LOAD';
export const AUTH_DATA_DELETE = 'AUTH_DATA_DELETE';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOADING = 'USER_LOADING';
export const USER_STOP_LOADING = 'USER_STOP_LOADING';

// SURVEYS
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const GET_SURVEYS = 'GET_SURVEYS';
export const DELETE_SURVEY = 'DELETE_SURVEY';
export const SURVEY_LOADING = 'SURVEY_LOADING';
export const SURVEY_STOP_LOADING = 'SURVEY_STOP_LOADING';

// TODO: REMOVE BELLOW

// PRODUCTS
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

// SITE
export const GET_SITE = 'GET_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const CREATE_SLIDE = 'CREATE_SLIDE';
export const UPDATE_SLIDE = 'UPDATE_SLIDE';
export const CREATE_ROW = 'CREATE_ROW';
export const UPDATE_ROW = 'UPDATE_ROW';
export const SITE_ERROR = 'SITE_ERROR';

// CART
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const ORDER_ERROR = 'ORDER_ERROR';

// ORDER
export const GET_CART = 'GET_CART';
export const GET_CART_SUMMARY = 'GET_CART_SUMMARY';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const CLEAR_CART = 'CLEAR_CART';
export const CLEAR_CART_SUMMARY = 'CLEAR_CART_SUMMARY';
export const CART_ERROR = 'CART_ERROR';

// SUBSCRIPTION
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CLEAR_ALL_SUBSCRIPTION = 'CLEAR_ALL_SUBSCRIPTION';
export const CLEAR_SUBSCRIPTION_SUMMARY = 'CLEAR_SUBSCRIPTION_SUMMARY';
export const GET_SUBSCRIPTION_SUMMARY = 'GET_SUBSCRIPTION_SUMMARY';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const CLEAR_SUBSCRIPTION = 'CLEAR_SUBSCRIPTION';
export const CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR';

export const SET_SUBSCRIPTION_PRICE_AND_TOTAL_ITEMS = 'SET_SUBSCRIPTION_PRICE_AND_TOTAL_ITEMS';
export const SET_SUBSCRIPTION_DAYS_AND_TYPE = 'SET_SUBSCRIPTION_DAYS_AND_TYPE';
export const SET_SUBSCRIPTION_FORM_DATA = 'SET_SUBSCRIPTION_FORM_DATA';
export const SET_INDIVIDUAL_PRODUCTS = 'SET_INDIVIDUAL_PRODUCTS';
export const SET_COUPLE_PRODUCTS = 'SET_COUPLE_PRODUCTS';
export const SET_SON_PRODUCTS = 'SET_SON_PRODUCTS';
export const SET_DAUGHTER_PRODUCTS = 'SET_DAUGHTER_PRODUCTS';
export const CLEAR_SUBSCRIPTION_FORM_DATA = 'CLEAR_SUBSCRIPTION_FORM_DATA';
export const CLEAR_SUBSCRIPTION_FORM_PRODUCTS = 'CLEAR_SUBSCRIPTION_FORM_PRODUCTS';

// CHECKOUT
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const SET_CHECKOUT_TYPE = 'SET_CHECKOUT_TYPE';
export const SET_CHECKOUT_VALUES = 'SET_CHECKOUT_VALUES';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';

// PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_DISPLAY_PROFILE = 'GET_DISPLAY_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const CLEAR_DISPLAY_PROFILE = 'CLEAR_DISPLAY_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';

// GLOBAL
export const SHOW_HEADER_MENU = 'SHOW_HEADER_MENU';
