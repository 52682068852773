import React, { Component } from 'react';
import AdminLoginForm from '../../components/AdminLoginForm';
import './index.scss';

class LoginLayout extends Component {
    render() {
        return (
            <div className="admin-login-form">
                <AdminLoginForm/>
            </div>
        );
    }
}

export default LoginLayout;
