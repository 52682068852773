import React, { Component } from 'react';
import Input from '../../../../../Core/components/Input';
// import './index.scss';

class EndScreen extends Component {

    handleAutogrowChange = ev => { // Autogrow text areas
        const el = ev.target;
        el.style.height = el.setAttribute('style', `height: ${ el.scrollHeight + 4 }px`);
        el.classList.add('auto');
        el.addEventListener('input', e => {
            el.style.height = 'auto';
            el.style.height = `${ el.scrollHeight + 4 }px`;
        });
    }

    render() {
        const { updateValue, endData } = this.props;

        return (
            <div className="options-box intro-screen">

                <textarea
                    rows="1"
                    placeholder="Add an end of survey title"
                    className="intro-title autogrow"
                    value={ endData.title }
                    onChange={ e => { this.handleAutogrowChange(e); updateValue('introData', 'title', e.target.value) } }
                />
                <textarea
                    rows="1"
                    placeholder="Click to add thank you message..."
                    className="intro-desc autogrow"
                    value={ endData.description }
                    onChange={ e => { this.handleAutogrowChange(e); updateValue('introData', 'description', e.target.value) } }
                />
            </div>
        );
    }
}

export default EndScreen;
