import React, { Component } from 'react';
import SurveyLayout from '../../layouts/Survey';
import img from '../../../../assets/images';

class Survey extends Component {

    state = {
        poll: {
            id: 59423524, name: null
        },
        questionList: [
            {
                allowSkipping: true,
                chooseMultiple: true,
                chosen: false,
                enableComments: false,
                id: 23040598,
                question: "How is the weather today?",
                questionDescription: "Please select how are you finding the weather today",
                selected: false,
                type: "multiple-choice",
                value: null,
                values: [
                    {id: 71890336, label: "Hot"},
                    {id: 90469389, label: "Rainy"},
                    {id: 10469389, label: "Cold"},
                ],
            },
            {
                allowSkipping: true,
                chooseMultiple: false,
                chosen: false,
                enableComments: true,
                id: 15040190,
                question: "Select your favorite fruit:",
                questionDescription: null,
                selected: false,
                type: "multiple-choice",
                value: null,
                values: [
                    {id: 21890336, label: "Orange", chosen: false, selected: false},
                    {id: 30469389, label: "Apple", chosen: false, selected: false},
                    {id: 50771307, label: "Grapes", chosen: false, selected: false},
                    {id: 10771307, label: "Bannana", chosen: false, selected: false},
                ],
            },
            {
                allowSkipping: true,
                chooseMultiple: false,
                chosen: false,
                enableComments: false,
                id: 34050196,
                question: "Do you prefer Xbox or PS4?",
                questionDescription: null,
                selected: false,
                type: "multiple-choice",
                value: null,
                values: [
                    {id: 71890336, label: "Ps4"},
                    {id: 90469389, label: "XBox"},
                    {id: 50771307, label: "I don't know"},
                ],
            },
            {
                allowSkipping: false,
                chooseMultiple: false,
                chosen: false,
                enableComments: false,
                id: 30693536,
                question: "How do you feel about our company?",
                questionDescription: "Please select how you feel with our customer service & products below:",
                type: "sentiment-choice",
                value: null,
                values: [],
            },
            {
                allowSkipping: false,
                chooseMultiple: false,
                enableComments: false,
                id: 92737471,
                question: "Please rate our headphones product below:",
                questionDescription: null,
                type: "rating",
                value: null,
                values: [],
            },
            {
                allowSkipping: false,
                chooseMultiple: false,
                enableComments: false,
                id: 18650945,
                question: "How likely is for you to buy from us again?",
                questionDescription: null,
                type: "scale",
                value: null,
                values: [
                    {value: 50, minLabel: "Not likelyq", maxLabel: "Very likely"},
                ]
            },
            {
                allowSkipping: false,
                chooseMultiple: false,
                enableComments: false,
                id: 44642059,
                question: "Please describe your overall experience",
                questionDescription: "Remember adding as much details as possible",
                type: "open-text",
                value: null,
                values: [],
            },
        ],
        style: {
            backgroundType: 'color', // null: none, 'color': color config, 'image': img url
            backgroundColor: '#222',
            backgroundImage: img.random.bg_buho2,
            fontFamily: null,
            fontColor: null,
            animation: 'fall', // Slider transition animation
            cssFile: null,
        },

        results: [
            // Sample result, after saving generate a surveyId
            /* {
                id: Math.floor(Math.random() * 99999999) + 10000000,
                questionId: null,
                type: null, // 'multiple-choice', 'sentiment-choice', etc
                value: null, // In case of single result
                values: [], // In case of array of results
            } */
        ]
    };

    componentDidMount() {
        const { questionList } = this.state;
        let prefilled = [];
        questionList.forEach(e => {
            prefilled.push({
                id: Math.floor(Math.random() * 99999999) + 10000000,
                questionId: e.id,
                type: e.type, // 'multiple-choice', 'sentiment-choice', etc
                value: null, // In case of single result
                values: [], // In case of array of results
            })
        })
        this.setState({ results: prefilled }, () => {
            console.log('Prefilled results state...', this.state.results)
        });
    }

    updateValue = (questionId, key, value) => {
        const newState = { ...this.state };
        const arrIndex = newState.results.findIndex(e => e.questionId === questionId);
        newState.results[arrIndex][key] = value;
        console.log('newState...', newState);
        return new Promise(resolve => this.setState(newState, resolve));
    }

    render() {
        const {
            poll,
            questionList,
            style,
            results,
        } = this.state;

        return (
            <SurveyLayout
                poll={ poll }
                questionList={ questionList }
                style={ style }
                results={ results }
                updateValue={ this.updateValue }
            />
        );
    }
}

export default Survey;
