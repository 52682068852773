import React from 'react';
import { Link } from 'react-router-dom';
import Input from '../Input';
import '../Login/index.scss';

class Register extends React.Component {
    state = {
        form: {
            name: null,
            email: null,
            password: null,
        },
    }

    updateValue = (key, value) => {
        const newState = { ...this.state };
        newState.form[key] = value;
        this.setState(newState);
    }

    registerUser = () => {
        // TODO: Display errors to the user
        const { form: { name, email, password } } = this.state;
        const { register, history } = this.props;

        if (name.length > 0 && email.length > 0 && password.length > 0) {
            register(email, name, password, () => history.push('/panel/dashboard'));
        }
    };

    render() {
        const { showLogin } = this.props;
        const { form: { name, lastName, email, password } } = this.state;
        return (
            <>
                <div className="auth-title">Register</div>
                <div className="box">
                    <div className="text-sm block color-gray margin-b20">Enter your login data:</div>
                    <div className="margin-b20">
                        <Input title="Full name" value={ name } onChange={ e => this.updateValue('name', e.target.value) } style={ { marginRight: 0 } } />
                    </div>
                    <div className="margin-b20">
                        <Input title="Email address" value={ email } onChange={ e => this.updateValue('email', e.target.value) } style={ { marginRight: 0 } } />
                    </div>
                    <div className="margin-b10">
                        <Input type="password" title="Password" value={ password } onChange={ e => this.updateValue('password', e.target.value) } style={ { marginRight: 0, marginBottom: 0 } } />
                    </div>

                    <button onClick={this.registerUser} className="btn-lg btn-primary btn-auth">Register</button>
                </div>

                <div className="block text-center margin-t30">
                    <div className="text-sm block color-gray margin-b20">Already have an account? <a onClick={ () => showLogin() } className="text-sm color-primary" role="button" tabIndex={ 0 }>Login</a></div>
                </div>

            </>
        );
    }
}

export default Register;