import React, { Component } from 'react';
import HomeLayout from '../../layouts/Home';

class Home extends Component {
    render() {
        const { langText } = this.props;
        
        return (
            <HomeLayout langText={ langText['home'] } />
        );
    }
}

export default Home;
