import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Gallery from '../../components/Gallery';
import ScheduleWidget from '../../components/ScheduleWidget';
import assets from '../../../../assets/images';
import './index.scss';

const listing = {
    id: 911460281,
    'image':'https://s3.amazonaws.com/sonautos17/houses/911460281/7676.jpeg',
    'latlon':[-103.44235100, 20.77260600],
    'title':'Lujoso departamento en Cima Park',
    'hab':2,
    'ban':2,
    'mts':94,
    'price': 21000,
    'address':'Av. Paseo La Toscana 122, Valle Real, 45019 Zapopan, Jal.',
    'state':'Jalisco',
    'city':'Zapopan',
};

class DescriptionLayout extends Component {

    state = {
    }

    render() {
        // const backgroundImage = `url(${ assets.home.preamble })`;

        return (
            <div className="description wrapper container">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                        <h1>{ listing.title }</h1>
                        <h4>{ listing.address }</h4>

                        <div className="row">
                            <div className="col-md-9">
                                <Gallery
                                    mainImage={ 'https://s3.amazonaws.com/sonautos17/houses/911460281/7676.jpeg' }
                                    secondImage={ 'https://s3.amazonaws.com/sonautos17/houses/101520108/4864.jpeg' }
                                    thirdImage={ 'https://s3.amazonaws.com/sonautos17/houses/294181762/3714.jpeg' }
                                    fourthImage={ 'https://s3.amazonaws.com/sonautos17/houses/395925463/2541.jpeg' }
                                    fifthImage={ 'https://s3.amazonaws.com/sonautos17/houses/645479488/7957.jpeg' }
                                />

                                <h2>Descripción</h2>
                                <p>You will find this special area to be very conveniently located to many shops, boutiques, markets, restaurants, cafes, bars, beer gardens, museums, parks, and any other establishment that one needs or wants to have nearby in an urban setting.</p>

                                <h2>Amenidades</h2>

                                <ul className="amenities">
                                    <li>Servicios y Confort</li>
                                    <li>Alberca</li>
                                    <li>Área de juegos</li>
                                    <li>Área de juegos</li>
                                    <li>Área de juegos</li>
                                    <li>Área de juegos</li>
                                    <li>Área de juegos</li>
                                    <li>Área de juegos</li>
                                    <li>Área de juegos</li>
                                </ul>

                                <h2>Ubicación</h2>

                                <div id="map-canvas-desc">
                                    <GoogleMapReact
                                        bootstrapURLKeys={ { key: 'AIzaSyCEaIi4ZzswD35y_g-yJupjadGmg-Pfkuk' } }
                                        defaultCenter={ {
                                            lat: 20.77260600,
                                            lng: -103.44235100,
                                        } }
                                        defaultZoom={ 15 }
                                    >
                                        <div
                                            className="map-marker"
                                            lat={ 20.77260600 }
                                            lng={ -103.44235100 }
                                        ></div>
                                    </GoogleMapReact>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="padding-l20">

                                    <div className="top-widget">
                                        <p className="price">$21,000<span>/ mes</span></p>

                                        {/* <hr /> */}

                                        <div className="row simple">
                                            <div className="col-xs-3"><i className="bed"></i>{ listing.hab }<span>Hab.</span></div>
                                            <div className="col-xs-3 col-md-offset-1"><i className="bath"></i>{ listing.ban }<span>Baños</span></div>
                                            <div className="col-xs-4 col-md-offset-1"><i className="sq"></i>{ listing.mts } m<sup>2</sup><span>Area</span></div>
                                        </div>

                                        <div className="row margin-t30">
                                            <div className="col-md-7 text-left">Disponibilidad...</div>
                                            <div className="col-md-5 text-right">Inmediata</div>
                                        </div>
                                        <div className="row margin-t10">
                                            <div className="col-md-7 text-left">Depósito...</div>
                                            <div className="col-md-5 text-right">$9,000</div>
                                        </div>

                                        <button className="btn-lg btn-blue btn-widget margin-t30">Aplicar ahora</button>
                                    </div>

                                    <div className="row margin-b30">
                                    </div>

                                    <ScheduleWidget />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DescriptionLayout);
