import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PaymentsLayout from '../../layouts/Payments';
// import assets from '../../../../assets/images';
// import './index.scss';

class Payments extends Component {

    state = {
    }

    componentDidMount() {
        //
    }

    render() {
        return (
            <PaymentsLayout />
        );
    }
}

export default withRouter(Payments);
