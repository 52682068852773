export default [
    {
        key: 'yes-no',
        title: 'Yes / No',
        description: 'Choose between 2 options',
    },
    {
        key: 'multiple-choice',
        title: 'Multiple choice',
        description: 'Choose between 1 or more options',
    },
    {
        key: 'open-text',
        title: 'Open text',
        description: 'Describe your thoughts on something',
    },
    {
        key: 'sentiment-choice',
        title: 'Sentiment choice',
        description: 'Choose how you feel about something',
    },
    {
        key: 'scale',
        title: 'Scale slider',
        description: 'Drag the slider to reflect perception',
    },
    {
        key: 'rating',
        title: 'Rating choice',
        description: 'Choose rating from 1 to 5',
    },
    {
        key: 'date-time',
        title: 'Date/Time input',
        description: 'Choose a date or time (or both)',
    },
    {
        key: 'image-choice',
        title: 'Image choice',
        description: 'Choose between a list of images',
    },
    {
        key: 'rank-list',
        title: 'Order/Rank list',
        description: 'Drag/Reorder a list of options',
    },
];