import axios from 'axios';
import {
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
} from '../constants/action-types';
import setAuthToken from '../utils/setAuthToken';
import config from '../config';

const configHeader = {
    headers: {
        'Content-Type': 'application/json',
    },
};

// Register User
export const register = (email, fullName, password, callback) => async dispatch => {
    const body = JSON.stringify({ fullName, email, password });

    try {
        const res = await axios.post(`${ config.api }/users`, body, configHeader);

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: res.data,
        });

        callback();
    } catch (e) {
        dispatch({ type: USER_REGISTER_FAIL });
    }

};

// Login User
export const login = (email, password, callback) => async dispatch => {

    const body = JSON.stringify({ email, password });

    try {
        // Call on backend
        const res = await axios.post(`${ config.api }/auth`, body, configHeader);

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: res.data,
        });

        // Load User Data and get Cart
        // dispatch(loadUser());
        callback();
    } catch (e) {
        dispatch({ type: USER_LOGIN_FAIL });
    }

};

// External Login
// TODO: REMOVE
export const externalLogin = (payload, pathQuery, history) => async dispatch => {

    const body = JSON.stringify(payload);

    try {
        // Call on backend
        const res = await axios.post(`${ config.api }/auth/external`, body, configHeader);

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: res.data,
        });

        // dispatch(loadUser());

        if (pathQuery === 'subscription') {
            history.push('/checkout?form=subscription');
        } else if (pathQuery === 'cart') {
            history.push('/checkout?form=cart');
        } else {
            history.push('/');
        }
    } catch (e) {
        dispatch({ type: USER_LOGIN_FAIL });
    }

};

// Logout - Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: USER_LOGOUT });
    // dispatch({ type: CLEAR_PROFILE });
    // dispatch({ type: CLEAR_DISPLAY_PROFILE });
};

// Load User
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(`${ config.api }/auth`);
        dispatch({
            type: USER_LOADED,
            payload: res.data,
        });
        // dispatch(getCart());
    } catch (e) {
        dispatch({ type: AUTH_ERROR });
        // dispatch(getCart());
    }
};
