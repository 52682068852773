import React from 'react';
import { Router, Switch, Route } from 'react-router';
import { createBrowserHistory } from 'history';

import LayoutRoute from './modules/Core/layouts/SiteLayout';

import Survey from './modules/Survey/containers/Survey';
import Home from './modules/Home2/containers/Home';
import Description from './modules/Description/containers/Description';

// import ProductsMain from './modules/Products/containers/ProductsMain';

import Login from './modules/Auth/containers/Login';

// Panel
import Dashboard from './modules/Panel/Dashboard/containers/Dashboard';
import Surveys from './modules/Panel/Surveys/containers/Surveys';
import Payments from './modules/Panel/Payments/containers/Payments';
import Account from './modules/Panel/Account/containers/Account';

import AdminLogin from './modules/Panel/Admin/containers/AdminLogin';
import 'antd/dist/antd.css';
import Register from './modules/Auth/containers/Register';
import VerifyAuth from './modules/Auth/containers/VeifyAuth';

import './global.scss';

const browserHistory = createBrowserHistory();

export const Routes = () => (
    <Router history={ browserHistory }>
        <Switch>

            <Route exact path="/survey" component={ Survey }/>

            {/* HOME */ }
            <LayoutRoute exact path="/" component={ Home }/>
            <LayoutRoute exact path="/detalle" component={ Description }/>
            {/* <LayoutRoute exact path="/detalle/:id/:title" component={ Mapa }/> */}

            {/* PRODUCTOS */ }
            {/* <LayoutRoute exact path="/productos/:category" component={ Products }/> */}

            {/* CUSTOMER / PANEL CLIENTE / INICIADO SESIÓN */ }
            {/* <LayoutRoute exact path="/client/orders" component={ Orders }/> */}
            {/* <LayoutRoute exact path="/client/subscription" component={ Subscription }/> */}

            {/* PANEL */ }
            <LayoutRoute exact path="/admin" component={ AdminLogin }/>

            {/* ADMIN PRODUCTS */ }
            <LayoutRoute exact path="/panel/dashboard" component={ Dashboard } authRoute />
            <LayoutRoute exact path="/panel/surveys" component={ Surveys } authRoute />
            <LayoutRoute exact path="/panel/pagos" component={ Payments } authRoute />
            <LayoutRoute exact path="/panel/account" component={ Account } authRoute />

            {/* CART */ }
            {/* <LayoutRoute exact path="/checkout" component={ Checkout }/> */}

            {/* LOGIN */ }
            <LayoutRoute exact path="/login" component={ Login }/>

            {/* VERIFY LOGIN */ }
            <LayoutRoute exact path="/auth" component={ VerifyAuth }/>

            {/* SIGNUP */ }
            <LayoutRoute exact path="/signup" component={ Register }/>

        </Switch>
    </Router>
);
