import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Images from '../../../../assets/images';
import './index.scss';

class Footer extends Component {
    render() {

        return (
            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="block-col">
                                <h5>Poupoll</h5>
                                <span><Link to="/about">About</Link></span><span><Link to="/terms">Terms &amp; conditions</Link></span>
                                <span><Link to="/help">Help</Link>
                                </span><span><Link to="/privacy">Privacy</Link>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="block-col">
                                <h5>Social</h5>
                                <span><a href="https://facebook.com/poupoll">Facebook</a>
                                </span><span><a href="https://www.instagram.com/poupoll">Instagram</a>
                                </span><span><a href="#">Twitter</a></span>
                            </div>
                        </div>
                    </div>
                </div><div className="bottom"><div className="container"><p className="left">Copyright © 2020 &nbsp;CasasCV. Todos los derechos reservados.</p></div></div>
            </footer>
        );
    }
}

export default Footer;
