import axios from 'axios';
import {
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    CLEAR_PROFILE,
    CLEAR_CART,
    CLEAR_DISPLAY_PROFILE,
    CLEAR_ALL_SUBSCRIPTION, CLEAR_SUBSCRIPTION_SUMMARY, CLEAR_CART_SUMMARY,
} from '../../../constants/action-types';
import setAuthToken from '../../../utils/setAuthToken';
import config from '../../../config';
// import { clearCartLocally, getCart, storeLocalStorageInDB } from '../../Cart/actions/cart-action';
// Set content type
const configHeader = {
    headers: {
        'Content-Type': 'application/json',
    },
};

// Register User
export const register = (email, password, history, pathQuery) => async dispatch => {

    const body = JSON.stringify({ email, password });

    try {
        // Call on backend
        const res = await axios.post(`${ config.api }/api/users`, body, configHeader);

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: res.data,
        });

        // Load user data in redux store
        dispatch(loadUser());
        if (localStorage.cart) {
            // dispatch(storeLocalStorageInDB());
        } else {
            // dispatch(getCart());
        }

        if (pathQuery === 'subscription') {
            history.push('/checkout?form=subscription');
        } else if (pathQuery === 'cart') {
            history.push('/checkout?form=cart');
        } else {
            history.push('/');
        }
    } catch (e) {
        dispatch({ type: USER_REGISTER_FAIL });
    }

};

// Login User
export const login = (email, password, history, adminPanel, pathQuery) => async dispatch => {

    const body = JSON.stringify({ email, password });

    try {
        // Call on backend
        const res = await axios.post(`${ config.api }/api/auth`, body, configHeader);

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: res.data,
        });

        // Load User Data and get Cart
        dispatch(loadUser());
        if (localStorage.cart) {
            // dispatch(storeLocalStorageInDB());
        } else {
            // dispatch(getCart());
        }

        if (adminPanel) { // login from admin panel
            history.push('/admin/products');
        } else {
            if (pathQuery === 'subscription') {
                history.push('/checkout?form=subscription');
            } else if (pathQuery === 'cart') {
                history.push('/checkout?form=cart');
            } else {
                history.push('/');
            }
        }
    } catch (e) {
        dispatch({ type: USER_LOGIN_FAIL });
    }

};

// External Login
export const externalLogin = (payload, pathQuery, history) => async dispatch => {

    const body = JSON.stringify(payload);

    try {
        // Call on backend
        const res = await axios.post(`${ config.api }/api/auth/external`, body, configHeader);

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: res.data,
        });

        // Load User Data and get Cart
        dispatch(loadUser());
        if (localStorage.cart) {
            // dispatch(storeLocalStorageInDB());
        } else {
            // dispatch(getCart());
        }

        if (pathQuery === 'subscription') {
            history.push('/checkout?form=subscription');
        } else if (pathQuery === 'cart') {
            history.push('/checkout?form=cart');
        } else {
            history.push('/');
        }
    } catch (e) {
        dispatch({ type: USER_LOGIN_FAIL });
    }

};

// Logout - Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: CLEAR_DISPLAY_PROFILE });
    dispatch({ type: CLEAR_CART });
    // dispatch(clearCartLocally());
    dispatch({ type: CLEAR_ALL_SUBSCRIPTION });
    dispatch({ type: CLEAR_SUBSCRIPTION_SUMMARY });
    dispatch({ type: CLEAR_CART_SUMMARY });
};

// Load User
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(`${ config.api }/api/auth`);
        dispatch({
            type: USER_LOADED,
            payload: res.data,
        });
        // dispatch(getCart());
    } catch (e) {
        dispatch({ type: AUTH_ERROR });
        // dispatch(getCart());
    }
};
