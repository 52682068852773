import React, { Component } from 'react';
import './index.scss';

// Default array for this ScaleSlider component
const defaultArr = [{
    value: 50, minLabel: null, maxLabel: null,
}];

class ScaleSlider extends Component {

    componentWillMount() {
        // If it's new, then set empty values
        const { values, updateValue } = this.props;
        const emptyArr = defaultArr;
        if (values.length === 0) {
            // Set defaultArr by default to prevent error while values arrive from props
            updateValue('values', emptyArr);
        }
    }

    handleChange = e => {
        const { updateValue, values } = this.props;
        const value = e.target.value;
        const newObj = [{ ...values[0] }];

        newObj[0].value = value;
        updateValue('values', newObj);
    }

    handleLabelUpdate = (e, type) => {
        const { updateValue, values } = this.props;
        const value = e.target.value;
        const newObj = [{ ...values[0] }];

        // type = min or max
        newObj[0][type] = value; 
        updateValue('values', newObj);
    }

    render() {
        const { values } = this.props;
        // Set defaultArr by default to prevent error while values arrive from props
        const val = values.length === 0 ? defaultArr[0] : values[0];

        return (
            <div className="scale-slider">

                <input
                    type="range"
                    id="range-input"
                    value={ val.value }
                    onChange={ this.handleChange }
                    style={ {
                        filter: `hue-rotate(-${ val.value }deg)`,
                    } }
                />
                <div id="h4-container">
                    <div id="h4-subcontainer">
                        <h4
                            style={ {
                                transform: `translateX(-19px) translateY(-125px) scale(1.3)`,
                                left: `${ val.value }%`
                            } }
                        >{ val.value }
                            <span
                                style={ {
                                    filter: `hue-rotate(-${ val.value }deg)`,
                                } }
                            ></span>
                        </h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <input
                            type="text"
                            value={ val.minLabel }
                            placeholder="Min. range label"
                            onChange={ e => this.handleLabelUpdate(e, 'minLabel') }
                        />
                    </div>
                    <div className="col-md-3 col-md-offset-6">
                        <input
                            type="text"
                            value={ val.maxLabel }
                            placeholder="Max. range label"
                            onChange={ e => this.handleLabelUpdate(e, 'maxLabel') }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ScaleSlider;
