import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import video from '../../../../assets/video';
import './index.scss';
import 'react-input-range/lib/css/index.css';


class HomeLayout extends Component {

    state = {
        selectedTab: 'one',
    }
    
    componentDidMount = () => {
        this.rotatingText();
    }

    pricePopupStatus = pricePopupShown => this.setState({ pricePopupShown });

    selectedTab = selectedTab => this.setState({ selectedTab });

    rotatingText = () => {
        let words = document.querySelectorAll(".word");
        words.forEach(word => {
            let letters = word.textContent.split("");
            word.textContent = "";
            letters.forEach(letter => {
                let span = document.createElement("span");
                span.textContent = letter;
                span.className = "letter";
                word.append(span);
            });
        });

        let currentWordIndex = 0;
        let maxWordIndex = words.length - 1;
        words[currentWordIndex].style.opacity = "1";

        let rotateText = () => {
            let currentWord = words[currentWordIndex];
            let nextWord =
            currentWordIndex === maxWordIndex ? words[0] : words[currentWordIndex + 1];
            // rotate out letters of current word
            Array.from(currentWord.children).forEach((letter, i) => {
            setTimeout(() => {
                letter.className = "letter out";
            }, i * 80);
            });
            // reveal and rotate in letters of next word
            (nextWord).style.opacity = "1";
            Array.from(nextWord.children).forEach((letter, i) => {
            letter.className = "letter behind";
            setTimeout(() => {
                letter.className = "letter in";
            }, 340 + i * 80);
            });
            currentWordIndex =
            currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
        };

        rotateText();
        setInterval(rotateText, 4000);
    }

    render() {
        const { selectedTab } = this.state;
        return (
            <div>
                <div className="preamble">
                    <div className="container">
                        <h1>Understand your 
                            <span className="rotating-text">
                                <p>
                                    <b className="word">customers.</b>
                                    {/* <b className="word">employees.</b> */}
                                    <b className="word">market.</b>
                                    <b className="word">attendees.</b>
                                </p>
                            </span>
                        </h1>
                        <h2>Blazing-fast way to create surveys users want to answer,<br/>no downloads needed.</h2>
                        {/* <h2>Real time surveys, no downloads needed.</h2> */}

                        <div className="block text-center">
                            <button type="button" className="btn-md call-to-action btn-primary">Start creating surveys &#x2192;</button>
                        </div>

                        <div className="row">
                            <div className="col-md-3 col-md-offset-1">
                                <ul className="list">
                                    <li className={ selectedTab === 'one' ? 'selected' : '' } onClick={ () => this.selectedTab('one') }>
                                        <div className="title">Blazing fast</div>
                                        <p>See how quick and easy is to create surveys that'll make you feel like a hero.</p>
                                    </li>
                                    <li className={ selectedTab === 'two' ? 'selected' : '' } onClick={ () => this.selectedTab('two') }>
                                        <div className="title">Customer satisfaction</div>
                                        <p>Learn exactly how your customers feel about your service - psst. we have sentiment analysis.</p>
                                    </li>
                                    <li className={ selectedTab === 'three' ? 'selected' : '' } onClick={ () => this.selectedTab('three') }>
                                        <div className="title">Live events</div>
                                        <p>Impress your attendees with in-event surveys &amp; live results.</p>
                                    </li>
                                    <li className={ selectedTab === 'five' ? 'selected' : '' } onClick={ () => this.selectedTab('five') }>
                                        <div className="title">Engaging surveys</div>
                                        <p>We know on behalf of your customers that answering surveys is boring, this is no longer true with poupoll.</p>
                                    </li>
                                    <li className={ selectedTab === 'four' ? 'selected' : '' } onClick={ () => this.selectedTab('four') }>
                                        <div className="title">Trust the data</div>
                                        <p>Data is important, results will be presented in an easy way to digest.</p>
                                    </li>
                                    <li className={ selectedTab === 'six' ? 'selected' : '' } onClick={ () => this.selectedTab('six') }>
                                        <div className="title">See it in action</div>
                                        <p>Don't only take our word, test it yourself.</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-7">
                                <div className="video">
                                    <video autoPlay muted loop>
                                        <source src={ video.landing.preamble }  type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <h3 className="h-title test-title margin-t10">Trusted by great people at</h3>
                    <div className="row testimonials">
                        <div className="col-md-2 testimonial">
                            <div className="one"></div>
                        </div>
                        <div className="col-md-2 testimonial">
                            <div className="two"></div>
                        </div>
                        <div className="col-md-2 testimonial">
                            <div className="three"></div>
                        </div>
                        <div className="col-md-2 testimonial">
                            <div className="four"></div>
                        </div>
                        <div className="col-md-2 testimonial">
                            <div className="five"></div>
                        </div>
                        <div className="col-md-2 testimonial">
                            <div className="six"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(HomeLayout);
