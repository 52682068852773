import React, { Component } from 'react';
import Input from '../../../../../../Core/components/Input';

class TransitionContainer extends Component {

    render() {
        const { updateValue, style } = this.props;

        return (
            <>
                <Input
                    type="select"
                    value={ style.transition }
                    onChange={ e => updateValue('style', 'transition', e.target.value) }
                    options={ [
                        {
                            value: 'cube',
                            text: 'Cube',
                        },
                        {
                            value: 'fall',
                            text: 'Fall',
                        },
                        {
                            value: 'fold-out',
                            text: 'Fold Out',
                        },
                        {
                            value: 'open',
                            text: 'Open',
                        },
                        {
                            value: 'scale-out',
                            text: 'Scale Out',
                        },
                    ]}
                />
            </>
        );
    }
}

export default TransitionContainer;
