import React, { Component } from 'react';
import LoginLayout from "../../layouts/Login";

class AdminLogin extends Component {
    render() {
        return (
            <div>
                <LoginLayout/>
            </div>
        );
    }
}

export default AdminLogin;
