import React, { Component } from 'react';
import Input from '../../../../../Core/components/Input';
import optionList from './optionList';
import MultipleChoice from './MultipleChoice';
import ScaleSlider from './ScaleSlider';
import SentimentChoice from './SentimentChoice';
import Rating from './Rating';
import YesNo from './YesNo';
import OpenText from './OpenText';
import ImageChoice from './ImageChoice';
import RankList from './RankList';
import './index.scss';
import DateTime from './DateTime';

class SelectQuestionType extends Component {

    state = {
        showOptionsBox: true,
        selectedOption: null,
        form: {
            id: null, // Math.floor(Math.random() * 99999999) + 10000000, // Random ID of 8 digits,
            question: null, // Question
            questionDescription: null, // Question description / more info
            type: null, // Type of question
            values: [], // If array of values
            value: null, // If single string value

            allowSkipping: false, // False: Skip the question, or True: required
            enableComments: false, // Enable a textarea at the end
            chooseMultiple: false, // For choices (false is checkbox, true is radiobutton)
        },
    }

    handleAutogrowChange = ev => { // Autogrow text areas
        const el = ev.target;
        el.style.height = el.setAttribute('style', `height: ${ el.scrollHeight + 4 }px`);
        el.classList.add('auto');
        el.addEventListener('input', e => {
            el.style.height = 'auto';
            el.style.height = `${ el.scrollHeight + 4 }px`;
        });
    }

    componentDidMount() { // Fixes switch from intro/end screen to question list
        const { questionData } = this.props;
        this.setState({ form: questionData[0] });
    }

    componentDidUpdate(prevProps, prevState, snapshot) { // If Edit field was pressed
        const { selectedQuestion, questionData, visible } = this.props;
        console.log('component did update')
        
        if (selectedQuestion !== prevProps.selectedQuestion) {
            this.setState({ form: questionData[0], showOptionsBox: false });
        }

        if (questionData !== prevProps.questionData) {
            this.setState({ form: questionData[0], showOptionsBox: true });
        }

        if (visible !== prevProps.visible) {
            this.setState({ showOptionsBox: visible });
        }
        
    }

    updateValue = (key, value) => {
        const { saveQuestion } = this.props; 
        const newState = { ...this.state };
        newState.form[key] = value;
        saveQuestion(newState.form);
        return new Promise(resolve => this.setState(newState, resolve));
    }

    render() {
        const {
            showOptionsBox,
            form: {
                question, questionDescription, type, values, value,
                allowSkipping, enableComments, chooseMultiple,
            },
        } = this.state;

        const {
            addNewQuestion,
            selectedQuestion,
        } = this.props;

        return (
            <div className="options-box">
                { !selectedQuestion && <h2>Select question type...</h2> }
                {/* <div className="close-box" onClick={ () => hideSelectQuestionType() }></div> */}

                { !selectedQuestion &&
                    <div className={ `options ${ showOptionsBox ? 'block' : 'hidden' }` }>
                        <div className="row">
                            { optionList.map((e, i) => 
                                <div key={ i } className="col-md-4">
                                    <div
                                        onClick={ () => { addNewQuestion(e.key); this.setState({ showOptionsBox: false }) } }
                                        className="option-item"
                                    >
                                        { e.title }<span>{ e.description }</span>
                                    </div>
                                </div>
                            ) }
                        </div>
                    </div>
                }
                
                { type &&
                    <>
                        <div className="extra-options-container">
                            <div className="row">
                                <div className="col-md-4">
                                    <Input type="checkbox"
                                        title="Allow skipping"
                                        value={ allowSkipping }
                                        onChange={ e => this.updateValue('allowSkipping', e) }
                                        style={ { marginBottom: '10px' } }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input type="checkbox"
                                        title="Choose Multiple"
                                        value={ chooseMultiple }
                                        onChange={ e => this.updateValue('chooseMultiple', e) }
                                        style={ { marginBottom: '10px' } }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input type="checkbox"
                                        title="Enable comments"
                                        value={ enableComments }
                                        onChange={ e => this.updateValue('enableComments', e) }
                                        style={ { marginBottom: '10px' } }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="question-data margin-b20">
                            <textarea
                                rows="1"
                                placeholder="Enter your question..."
                                className="question autogrow"
                                value={ question }
                                onChange={ e => { this.handleAutogrowChange(e); this.updateValue('question', e.target.value) } }
                            />

                            <div className="margin-b20">
                                <textarea
                                    rows="1"
                                    className="questionDesc autogrow"
                                    placeholder="Click to add a description..."
                                    value={ questionDescription }
                                    onChange={ e => { this.handleAutogrowChange(e); this.updateValue('questionDescription', e.target.value) } }
                                />
                            </div>
                        </div>

                        <hr />
                    </>
                }
                
                { type === 'yes-no' &&
                    <YesNo
                        values={ values }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'multiple-choice' &&
                    <MultipleChoice
                        values={ values }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'open-text' &&
                    <OpenText
                        value={ value }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'scale' &&
                    <ScaleSlider
                        values={ values }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'sentiment-choice' &&
                    <SentimentChoice
                        value={ value }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'rating' &&
                    <Rating
                        value={ value }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'date-time' &&
                    <DateTime
                        values={ values }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'image-choice' &&
                    <ImageChoice
                        values={ values }
                        updateValue={ this.updateValue }
                    />
                }

                { type === 'rank-list' &&
                    <RankList
                        values={ values }
                        updateValue={ this.updateValue }
                    />
                }

                { type &&
                    <>
                        {/* <hr />
        
                        <div className="block text-center margin-t20">
                            <div className="btn-lg btn-primary" onClick={ () => saveQuestion(form) }>Save question</div>
                        </div> */}
                    </>
                }
            </div>
        );
    }
}

export default SelectQuestionType;
