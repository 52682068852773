import React, { Component } from 'react';
import DescriptionLayout from '../../layouts/Description';

class Description extends Component {
    render() {
        return (
            <DescriptionLayout />
        );
    }
}

export default Description;
