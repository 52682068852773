import React, { Component } from 'react';
import PricingPlans from './PricingPlans';
import Checkout from './Checkout';
import './index.scss';

class Pricing extends Component {

    state = {
        step: 'plans', // plans, address, checkout
        form: {
            plan: null,
            billing: {
                period: 'monthly', // monthly, yearly
                name: null,
                email: null,
                needInvoice: 'no',
                businessName: null,
                vat: null, // VAT number for some Europe countries (not sure if needed)
                rfc: null, // Just for Mexico
            },
            address: {
                address: null,
                city: null,
                state: null,
                country: 'US',
                zip: null,
            }
        }
    };

    setStep = step => this.setState({ step });

    updateValue = (step, key, value) => {
        const newState = { ...this.state };
        if (step !== null) { newState.form[step][key] = value;
        } else { newState.form[key] = value; }
        console.log('newState...', newState); // TODO: Remove logs
        return new Promise(resolve => this.setState(newState, resolve));
    }

    render() {
        const { step, form } = this.state;
        const { hideModal } = this.props;
        return (
            <>
                <div className={ `full-modal-close ${ step === 'plans' ? 'white' : 'dark' }` } onClick={ () => hideModal() }></div>
                { step === 'plans' ?
                    <PricingPlans
                        setStep={ this.setStep }
                        updateValue={ this.updateValue }
                    />
                    : <Checkout
                        form={ form }
                        step={ step }
                        setStep={ this.setStep }
                        updateValue={ this.updateValue }
                    />
                }
            </>
        );
    }

}

export default Pricing;
